import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import InnerHeader from '../../components/innerHeader'
import FormInput from '../../components/interfaceComponents/FormInput'
import SelectForm from '../../components/interfaceComponents/SelectForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { trimObjectFields } from '../../helpers/utils'
import isEmpty from 'lodash/isEmpty'
import { transactionYupSchemes } from '../../helpers/yupSchemes/transactionYupSchemes'
import { CompanyRecipient, KWLRecipient } from '../../interfaces/beneficiary'
import { transactionDataInLocalStorage } from '../../helpers/localStorage/transactionData'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { reasonForTransferOptions, sourceOfFundsOptions } from '../../helpers/configs/optionConfigs'
import CalculationSection from '../calculation/CalculationSection'
import { calculationHelper } from '../../helpers/calculationData'
import omit from 'lodash/omit'
import {
  OtherTransactionDetailsForm,
  ReasonForTransfer,
  SourceOfFunds,
  TransactionDataStateForNewTx,
} from '../../interfaces/remittanceType'
import FakeInput from '../interfaceComponents/FakeInput'
import { Icon } from '../icons'
import { WithdrawalOption } from '../../interfaces/calculation'
import { PaymentMethod } from '../../interfaces'
import ControlledSelectorForm from '../interfaceComponents/ControledSelectorForm'
import { useAppSelector } from '../../hooks/dispatch'
import { localStorageHelper } from '../../helpers/useLocalStorage'

type LocationState = {
  state: {
    searchedTxValue: string
    searchedValue: string
    from: string
  }
}

type OtherTransactionDetailsProps = {
  recipient?: KWLRecipient | CompanyRecipient
  setCurrentSection: Dispatch<SetStateAction<string>>
  transactionData: TransactionDataStateForNewTx
  setTransactionData: Dispatch<SetStateAction<TransactionDataStateForNewTx>>
  recipientData: KWLRecipient | CompanyRecipient | undefined
  withdrawalPaymentProviderOptions: Array<WithdrawalOption>
}

const OtherTransactionDetails = ({
  setCurrentSection,
  transactionData,
  setTransactionData,
  recipientData,
  withdrawalPaymentProviderOptions,
}: OtherTransactionDetailsProps) => {
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const from = location?.state?.from || ''
  const searchedValue = location?.state?.searchedValue || undefined
  const searchedTxValue = location?.state?.searchedTxValue || undefined
  const getWithdrawalPaymentProviderLoadState = useAppSelector(
    state => state.remittance.getWithdrawalPaymentProviderLoadState
  )
  const receivePaymentData = localStorageHelper.getData('receiveMethod')

  const [receive, setReceive] = useState<number | undefined | string>()
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [showReasonDetailsField, setShowReasonDetailsField] = useState(false)
  const [showSourceDetailsField, setShowSourceDetailsField] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<OtherTransactionDetailsForm>({
    resolver: yupResolver(transactionYupSchemes.transactionDetailsScheme),
  })

  const sourceOfFundsWatch = watch('sourceOfFunds')
  const reasonTransferWatch = watch('reasonTransfer')
  const watchedReceiveMethod = watch('receiveMethod')

  const isShownAccountNumberField = useMemo(
    () =>
      Number(watchedReceiveMethod?.paymentMethod) === PaymentMethod.BankTransfer &&
      recipientData?.accountNumber,
    [recipientData?.accountNumber, watchedReceiveMethod?.paymentMethod]
  )

  const isShownBeneficiaryAccountNumberError =
    Number(watchedReceiveMethod?.paymentMethod) === PaymentMethod.BankTransfer &&
    !recipientData?.accountNumber

  const isDisabledBtn = !isEmpty(errors) || isDisabledButton || isShownBeneficiaryAccountNumberError

  const selectedReceiveMethod = useMemo(() => {
    return withdrawalPaymentProviderOptions.find(
      item =>
        item.paymentProviderId === transactionData.receiveMethod?.paymentProviderId &&
        item.currency === transactionData.receiveMethod.currency
    )
  }, [
    transactionData.receiveMethod?.currency,
    transactionData.receiveMethod?.paymentProviderId,
    withdrawalPaymentProviderOptions,
  ])

  const setInitialFormValue = useCallback(
    (transactionData: TransactionDataStateForNewTx) => {
      setValue('sourceOfFunds', transactionData.transactionDetails.sourceOfFunds)
      setValue('sourceOfFundsDetails', transactionData.transactionDetails.sourceOfFundsDetails)
      setValue('reasonTransfer', transactionData.transactionDetails.reasonTransfer)
      setValue('reasonTransferDetails', transactionData.transactionDetails.reasonTransferDetails)
      // setValue('receiveMethod', transactionData.receiveMethod)
      setValue('receiveMethod', selectedReceiveMethod)
    },
    [selectedReceiveMethod, setValue]
  )

  useEffect(() => {
    if (transactionData) {
      setInitialFormValue(transactionData)
    }
  }, [setInitialFormValue, transactionData])

  useEffect(() => {
    if (sourceOfFundsWatch) {
      if (sourceOfFundsWatch === String(SourceOfFunds.Other)) {
        setShowSourceDetailsField(true)
      } else {
        setShowSourceDetailsField(false)
        setValue('sourceOfFundsDetails', null)
        clearErrors('sourceOfFundsDetails')
      }
    }
  }, [clearErrors, setValue, sourceOfFundsWatch])

  useEffect(() => {
    if (reasonTransferWatch) {
      if (reasonTransferWatch === String(ReasonForTransfer.Other)) {
        setShowReasonDetailsField(true)
      } else {
        setShowReasonDetailsField(false)
        setValue('reasonTransferDetails', null)
        clearErrors('reasonTransferDetails')
      }
    }
  }, [clearErrors, reasonTransferWatch, setValue])

  const onNext = (data: OtherTransactionDetailsForm) => {
    const trimmedFormData = trimObjectFields<OtherTransactionDetailsForm>(data)
    const transactionDetails = omit(data, 'receiveMethod')

    const updatedTransactionData = {
      ...transactionData,
      transactionDetails,
      receiveMethod: trimmedFormData.receiveMethod,
    }

    setTransactionData(updatedTransactionData)
    transactionDataInLocalStorage.setTransactionData(updatedTransactionData)
    setCurrentSection('transaction-summary')
    calculationHelper.setCalculationData(receive)
  }

  const onGoBack = () => {
    navigate(from, { state: { searchedValue, searchedTxValue } })
  }

  const onCancel = () => {
    navigate('/')
  }

  return !getWithdrawalPaymentProviderLoadState.isLoading ? (
    <section className="h-100-mb">
      <div className="container container-md container-100">
        <InnerHeader onGoBack={onGoBack} title="Transaction Details" />
        <CalculationSection
          mode="calculation-input-with-recipient"
          receiveAmount={receive}
          setReceiveAmount={setReceive}
          setIsDisabledButton={setIsDisabledButton}
          recipient={recipientData as KWLRecipient}
          currency={receivePaymentData.currency}
          currencySign={receivePaymentData.currencySign}
        />
        <div className="user-info-select">
          <form id="other-transaction-details-form" onSubmit={handleSubmit(onNext)}>
            <SelectForm<OtherTransactionDetailsForm>
              id="sourceOfFunds"
              register={register}
              label="Source of Funds"
              options={sourceOfFundsOptions}
              className="select-form"
              error={errors.sourceOfFunds?.message}
              defaultOption="Select Source of Funds..."
              watch={watch}
            />
            {showSourceDetailsField && (
              <FormInput<OtherTransactionDetailsForm>
                id="sourceOfFundsDetails"
                register={register}
                error={errors.sourceOfFundsDetails?.message}
                className="formInput"
                type="text"
                label="Source of Funds Details"
                maxLength={64}
              />
            )}
            <SelectForm<OtherTransactionDetailsForm>
              id="reasonTransfer"
              register={register}
              error={errors.reasonTransfer?.message}
              label="Reason for Transfer"
              options={reasonForTransferOptions}
              className="select-form"
              defaultOption="Select Reason..."
              watch={watch}
            />
            {showReasonDetailsField && (
              <FormInput<OtherTransactionDetailsForm>
                id="reasonTransferDetails"
                register={register}
                error={errors.reasonTransferDetails?.message}
                className="formInput"
                type="text"
                label="Reason Details"
                maxLength={64}
              />
            )}
            {withdrawalPaymentProviderOptions.length > 0 && (
              <ControlledSelectorForm<OtherTransactionDetailsForm>
                name="receiveMethod"
                className="select-form"
                control={control}
                label="How would you like the recipient to receive funds?"
                options={withdrawalPaymentProviderOptions}
              />
            )}
          </form>

          {withdrawalPaymentProviderOptions.length > 0 && isShownAccountNumberField && (
            <FakeInput label="Account number" value={recipientData?.accountNumber} />
          )}

          {withdrawalPaymentProviderOptions.length > 0 && isShownBeneficiaryAccountNumberError && (
            <div className="beneficiary-account-warning">
              <Icon.WarningExclamationMark />
              <p>
                <Link to={`/beneficiary/${recipientData?.id}`} state={{ editMode: true }}>
                  Beneficiary
                </Link>{' '}
                does not have a Bank account number. Please add it and try to send the Transaction
                again.
              </p>
            </div>
          )}
        </div>

        <div className="btn-wrapper mb-10 user-info-button">
          <button type="button" className="btn btn-cancel" onClick={onCancel}>
            Cancel
          </button>
          <button
            form="other-transaction-details-form"
            type="submit"
            className="btn btn-next"
            disabled={isDisabledBtn}
          >
            Send Money
          </button>
        </div>
      </div>
    </section>
  ) : null
}

export default OtherTransactionDetails
