import { useLocation, useNavigate } from 'react-router-dom'
import { calculationHelper } from '../../helpers/calculationData'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { calculationActions } from '../../redux/action-creators/calculationActions'
// import debounce from 'lodash/debounce'
import { CalculationLimit, UserStatus } from '../../interfaces/user'
import { beneficiaryService } from '../../services/beneficiaryService'
import isEmpty from 'lodash/isEmpty'
import { recipientCountryInLocalStorage } from '../../helpers/localStorage/recipientCountry'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'
import { CountryOption } from '../../interfaces/beneficiary'
import { isUserDataExist } from '../../helpers/userHelper'
import {
  CashInCountryOption,
  PostCalculateServerRequest,
  WithdrawalOption,
} from '../../interfaces/calculation'
import { authHelper } from '../../helpers/authData'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import CalculationFormForHomePage from '../../components/calculation/calculationFormForHomePage/CalculationFormForHomePage'

type CalculationForm = {
  receiveAmount: string | number
  withdrawalCountry: {
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }
  totalAmountToSend: string | number
  cashInCountry: {
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }
  withdrawalOption:
    | (WithdrawalOption & {
        currency: string
        currencySign: string
      })
    | null
}

// type QueryParams = {
//   srcCurr: string
//   destAmount: string
//   destCurr: string
// }

type LocationState = {
  state: {
    from: string
  }
}

const CalculationPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const token = useMemo(() => authHelper.getAuthData(), [])
  // const [queryParams] = useSearchParams()
  const location = useLocation() as LocationState
  const from = location?.state?.from || ''

  const {
    totalAmountToSend,
    rate,
    minAmountToReceive,
    maxAmountToReceive,
    isLoading,
    isLoaded,
    withdrawalFeeSrcCurr,
  } = useAppSelector(state => state.calculation)
  const userStatus = useAppSelector(state => state.user.userDetails?.status)
  const { userDetails, userLimits } = useAppSelector(state => state.user)
  const { recipientCountryList: countryList } = useAppSelector(state => state.beneficiary)
  const preset = useAppSelector(state => state.preset.preset)
  // const withdrawalPaymentProvider = useAppSelector(
  //   state => state.remittance.withdrawalPaymentProvider,
  //   isEqual
  // )
  const withdrawalPaymentOptionsByCountry = useAppSelector(
    state => state.remittance.withdrawalPaymentOptionsByCountry
  )
  const getWithdrawalPaymentOptionsByCountryLoadState = useAppSelector(
    state => state.remittance.getWithdrawalPaymentOptionsByCountryLoadState
  )
  // const getWithdrawalPaymentProviderLoadState = useAppSelector(
  //   state => state.remittance.getWithdrawalPaymentProviderLoadState
  // )

  const [inputCounter, setInputCounter] = useState(0)
  const [send, setSend] = useState<number | string>('')
  const [receive, setReceive] = useState<number | undefined | string>(500)
  // const [errorVariant, setErrorVariant] = useState<
  //   '' | 'instance-min-limit' | 'instance-max-limit' | 'user-max-limit' | 'multiples'
  // >('')
  const [selectedCountry, setSelectedCountry] = useState<CountryOption | undefined>()
  // const [selectedReceiveMethod, setSelectedReceiveMethod] = useState<WithdrawalOption | undefined>()
  // const [selectedCashInCountry, setSelectedCashInCountry] = useState<
  //   CashInCountryOption | undefined
  // >()
  // const [amountFocus, setAmountFocus] = useState('')
  const [withdrawalFee, setWithdrawalFee] = useState<number | string>('')

  // const srcCurrency = useMemo(() => {
  //   return window.location.hostname === 'kwl.newlinetechno.net' ? 'EUR' : 'GBP'
  // }, [])

  useEffect(() => {
    // dispatch(remittanceActions.getWithdrawalPaymentProviderList('USD'))
    dispatch(remittanceActions.getWithdrawalPaymentOptionsByCountry())
    dispatch(remittanceActions.getCashInCountryList())
  }, [dispatch])

  // const withdrawalPaymentProviderOptions = useMemo(
  //   // () => getWithdrawalPaymentOptionsForHomePage(withdrawalPaymentProvider),
  //   () => getWithdrawalPaymentOptionsByCountryForHomePage(withdrawalPaymentOptionsByCountry, 240),
  //   [withdrawalPaymentOptionsByCountry]
  // )

  // useEffect(() => {
  //   if (withdrawalPaymentProviderOptions.length > 0) {
  //     setSelectedReceiveMethod(withdrawalPaymentProviderOptions[0])
  //   }
  // }, [withdrawalPaymentProviderOptions])

  // useEffect(() => {
  //   if (cashInCountry.length > 0) {
  //     setSelectedCashInCountry(cashInCountry[0])
  //   }
  // }, [cashInCountry])

  // const isRateShown = useMemo(() => {
  //   return (
  //     window.location.hostname === 'hre.kuva.com' ||
  //     window.location.hostname === 'remittance.uat.kuvacash.net'
  //   )
  // }, [])

  // const urlParams = useMemo((): QueryParams => {
  //   const params = { srcCurr: '', destAmount: '', destCurr: '' }

  //   for (const item of queryParams.entries()) {
  //     const [param, value] = item

  //     params[param] = value
  //   }

  //   return params
  // }, [queryParams])

  const postCalculation = useCallback(
    async (
      body: PostCalculateServerRequest,
      checkReceiveField: (value: number, limits: Array<CalculationLimit>) => void
    ) => {
      //TO DO: change logic to remove token from this func
      if (token) {
        await dispatch(calculationActions.postAuthorizeCalculationAction(body, checkReceiveField))
      } else {
        await dispatch(calculationActions.postAnonymousCalculationAction(body, checkReceiveField))
      }
    },
    [dispatch, token]
  )

  // const checkField = useCallback(
  //   (value: number) => {
  //     if (preset?.receiveAmountMultiplesOf && value % preset?.receiveAmountMultiplesOf) {
  //       setErrorVariant('multiples')
  //       return false
  //     }

  //     if (minAmountToReceive !== 0 && value < minAmountToReceive) {
  //       setErrorVariant('instance-min-limit')
  //       return false
  //     }

  //     if (userDetails?.status === UserStatus.Accepted) {
  //       const limits = userLimits.filter(
  //         limit =>
  //           limit.period !== CalculationLimitPeriod.MinPerTransaction &&
  //           limit.period !== CalculationLimitPeriod.MaxPerTransaction
  //       )

  //       const isExceededUserLimit = limits.find(
  //         limitItem => limitItem.remainingAmount !== -1 && limitItem.remainingAmount < value
  //       )
  //       if (isExceededUserLimit) {
  //         setErrorVariant('user-max-limit')
  //         return false
  //       }
  //     }

  //     if (maxAmountToReceive !== 0 && value > maxAmountToReceive) {
  //       setErrorVariant('instance-max-limit')
  //       return false
  //     }

  //     setErrorVariant('')
  //     return true
  //   },
  //   [
  //     maxAmountToReceive,
  //     minAmountToReceive,
  //     preset?.receiveAmountMultiplesOf,
  //     userDetails?.status,
  //     userLimits,
  //   ]
  // )

  // useEffect(() => {
  //   if (typeof receive === 'number') {
  //     checkField(receive)
  //   }
  // }, [checkField, receive])

  // const makeInitializationRequest = useCallback(
  //   (cashInCountry: CashInCountryOption, receiveMethod?: WithdrawalOption) => {
  //     let body = {
  //       sendCurrency: cashInCountry.currency,
  //       receiveAmount: 500,
  //       receiveCurrency: 'USD',
  //       ...(receiveMethod
  //         ? {
  //             withdrawalProviderId: receiveMethod?.value,
  //             withdrawalMethod: receiveMethod.paymentMethod,
  //           }
  //         : {}),
  //       receiveCountryId: 240,
  //       sourceCountryId: cashInCountry.value,
  //     }
  //     // if (+urlParams.destAmount) {
  //     //   body = { ...body, ...{ receiveAmount: +urlParams.destAmount } }
  //     // }
  //     if (from === '/confirm-email' || from === '/email-verification-pending') {
  //       const receiveAmount = Number(calculationHelper.getCalculationData()) || 500
  //       body = { ...body, receiveAmount }
  //     }

  //     setReceive(body.receiveAmount)

  //     postCalculation(body)
  //   },
  //   [from, postCalculation]
  // )

  // useEffect(() => {
  //   if (getWithdrawalPaymentOptionsByCountryLoadState.isLoaded) {
  //     makeInitializationRequest(cashInCountry[0], withdrawalPaymentProviderOptions[0])
  //   }
  // }, [
  //   cashInCountry,
  //   getWithdrawalPaymentOptionsByCountryLoadState.isLoaded,
  //   makeInitializationRequest,
  //   withdrawalPaymentProviderOptions,
  // ])

  useEffect(() => {
    return () => {
      dispatch(calculationActions.removeTotalAmountToSendFromState())
    }
  }, [dispatch])

  useEffect(() => {
    if (isEmpty(countryList)) {
      dispatch(beneficiaryActions.getBeneficiaryCountryList())
    }
  }, [countryList, dispatch])

  // const makeCalculation = useCallback(
  //   (inputValue: number) => {
  //     const body = {
  //       sendCurrency: selectedCashInCountry!.currency,
  //       receiveCurrency: 'USD',
  //       receiveAmount: inputValue,
  //       ...(selectedReceiveMethod
  //         ? {
  //             withdrawalProviderId: selectedReceiveMethod?.value,
  //             withdrawalMethod: selectedReceiveMethod.paymentMethod,
  //           }
  //         : {}),
  //       receiveCountryId: 240,
  //       sourceCountryId: selectedCashInCountry!.value,
  //     }

  //     postCalculation(body)
  //   },
  //   [postCalculation, selectedCashInCountry, selectedReceiveMethod]
  // )

  // const makeCalculationAfterReceiveMethod = useCallback(
  //   (receiveMethod: WithdrawalOption) => {
  //     const body = {
  //       sendCurrency: selectedCashInCountry!.currency,
  //       receiveCurrency: 'USD',
  //       receiveAmount: Number(receive),
  //       ...(receiveMethod
  //         ? {
  //             withdrawalProviderId: receiveMethod?.value,
  //             withdrawalMethod: receiveMethod.paymentMethod,
  //           }
  //         : {}),
  //       receiveCountryId: 240,
  //       sourceCountryId: selectedCashInCountry!.value,
  //     }

  //     receive && postCalculation(body)
  //   },
  //   [postCalculation, receive, selectedCashInCountry]
  // )

  // const makeCalculationAfterSelectingCashInCountry = useCallback(
  //   (cashInCountry: CashInCountryOption) => {
  //     const body = {
  //       sendCurrency: cashInCountry.currency,
  //       receiveCurrency: 'USD',
  //       receiveAmount: Number(receive),
  //       ...(selectedReceiveMethod
  //         ? {
  //             withdrawalProviderId: selectedReceiveMethod?.value,
  //             withdrawalMethod: selectedReceiveMethod.paymentMethod,
  //           }
  //         : {}),
  //       receiveCountryId: 240,
  //       sourceCountryId: cashInCountry.value,
  //     }

  //     receive && postCalculation(body)
  //   },
  //   [postCalculation, receive, selectedReceiveMethod]
  // )

  // const debounceFunction = useMemo(() => debounce(makeCalculation, 600), [makeCalculation])
  // const debounceFunctionReceiveMethod = useMemo(
  //   () => debounce(makeCalculationAfterReceiveMethod, 600),
  //   [makeCalculationAfterReceiveMethod]
  // )
  // const debounceFunctionCashInCountry = useMemo(
  //   () => debounce(makeCalculationAfterSelectingCashInCountry, 600),
  //   [makeCalculationAfterSelectingCashInCountry]
  // )

  useEffect(() => {
    if (selectedCountry) {
      recipientCountryInLocalStorage.setRecipientCountry(selectedCountry)
    }
  }, [selectedCountry])

  useEffect(() => {
    if (isLoaded && !isLoading && totalAmountToSend !== 0) {
      setSend(totalAmountToSend)
    }
    if (isLoaded && !isLoading && withdrawalFeeSrcCurr !== null && totalAmountToSend !== 0) {
      setWithdrawalFee(withdrawalFeeSrcCurr)
    }
  }, [isLoaded, isLoading, totalAmountToSend, withdrawalFeeSrcCurr])

  const moveToNextRoute = useCallback(async () => {
    const isEmailConfirmed = userDetails && userDetails?.isEmailConfirmed
    const isFilledUserDetails = userDetails && isUserDataExist(userDetails)

    if (userStatus === UserStatus.Accepted) {
      if (isFilledUserDetails && isEmailConfirmed) {
        const offset = 0
        const step = 1
        const beneficiaries = await beneficiaryService.getBeneficiaries(offset, step)

        if (isEmpty(beneficiaries.items)) {
          navigate('/beneficiary/new', { state: { from: 'continue-transaction' } })
        } else {
          navigate('/beneficiaries/select-beneficiary')
        }
      }

      if (isFilledUserDetails && !isEmailConfirmed) {
        navigate('/email-verification-pending', { state: { from: 'continue-transaction' } })
      }

      if (!isFilledUserDetails) {
        navigate('/sing-up/user-details', { state: { from: 'continue-transaction' } })
      }
    }

    if (!userStatus) {
      navigate('/login', { state: { from: 'continue-transaction' } })
    }
  }, [navigate, userDetails, userStatus])

  const onReceiveChange = (value: number | undefined) => {
    setReceive(value)
    setSend('')
    setWithdrawalFee('')

    // if (value) {
    //   checkField(value) && debounceFunction(value)
    // }
  }
  const onContinueClick = (formData: Omit<CalculationForm, 'totalAmountToSend'>) => {
    // event.preventDefault()

    calculationHelper.setCalculationData(formData.receiveAmount)
    localStorage.setItem('receiveMethod', JSON.stringify(formData.withdrawalOption))

    const calcData = {
      sendCurrency: formData.cashInCountry.currency || '',
      receiveCurrency: formData.withdrawalOption!.currency,
      receiveAmount: Number(formData.receiveAmount),

      withdrawalProviderId: formData.withdrawalOption!.value,
      withdrawalMethod: formData.withdrawalOption!.paymentMethod,

      receiveCountryId: formData.withdrawalCountry!.value,
      sourceCountryId: formData.cashInCountry!.value,
    }

    calculationHelper.setCalculationDataV2(calcData)

    moveToNextRoute()
  }

  const sectionClass = useMemo(() => {
    if (userDetails !== null) {
      if (userDetails?.status === UserStatus.Accepted) {
        return ' logIn'
      } else {
        return ' nonAcceptedUser'
      }
    }
    return ''
  }, [userDetails])

  // const errorText = useMemo(() => {
  //   if (errorVariant === 'multiples') {
  //     return `The amount must be a multiple of ${preset?.receiveAmountMultiplesOf}`
  //   }

  //   if (errorVariant === 'instance-min-limit') {
  //     return `The amount is less than the lower limit $${minAmountToReceive}`
  //   }

  //   if (errorVariant === 'instance-max-limit') {
  //     return `The amount is greater than the upper limit $${maxAmountToReceive}`
  //   }

  //   if (errorVariant === 'user-max-limit') {
  //     return (
  //       <>
  //         Your daily/monthly limit has been exceeded. More info&nbsp;
  //         <Link to="/profile" state={{ pathname: '/', tab: 'limits' }}>
  //           here
  //         </Link>
  //       </>
  //     )
  //   }
  //   return ''
  // }, [errorVariant, maxAmountToReceive, minAmountToReceive, preset?.receiveAmountMultiplesOf])

  // const calcData = useMemo(
  //   () => ({
  //     totalAmountToSend,
  //     rate,
  //   }),
  //   [rate, totalAmountToSend]
  // )

  const onMainSubmit = useCallback(
    (
      formData: Omit<CalculationForm, 'totalAmountToSend'>,
      checkReceiveField: (value: number, limits: Array<CalculationLimit>) => void
    ) => {
      const body = {
        sendCurrency: formData.cashInCountry.currency || '',
        receiveCurrency: formData!.withdrawalOption!.currency,
        receiveAmount: Number(formData.receiveAmount),

        withdrawalProviderId: formData!.withdrawalOption!.paymentProviderId,
        withdrawalMethod: formData!.withdrawalOption!.paymentMethod,

        receiveCountryId: formData.withdrawalCountry.value,
        sourceCountryId: formData.cashInCountry.value,
      }

      postCalculation(body, checkReceiveField)
    },
    [postCalculation]
  )

  return (
    <section className={`h-100-mb${sectionClass}`}>
      <div className="container container-md container-100">
        <CalculationFormForHomePage
          className={`send-block${sectionClass}`}
          onParentSubmit={onMainSubmit}
          onContinueClick={onContinueClick}
        />
      </div>
    </section>
  )
}
export default CalculationPage
