import { receiveMethodSelectorConfig } from '../../interfaceComponents/CustomSelector/config/receiveMethodSelectorConfig'
import MoneyInputWithCountrySelector from '../moneyInputWithCountrySelector/MoneyInputWithCountrySelector'
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../../hooks/dispatch'
import {
  getCountryOptions,
  getWithdrawalPaymentOptionsByCountryForHomePage,
} from '../../../helpers/calcHelper'
import { CalculationLimit, CalculationLimitPeriod, UserStatus } from '../../../interfaces/user'
import { Link } from 'react-router-dom'
import CustomSelector from '../../interfaceComponents/CustomSelector/CustomSelector'
import { WithdrawalOption } from '../../../interfaces/calculation'
import { Icon } from '../../icons'
import debounce from 'lodash/debounce'

type CalculationForm = {
  receiveAmount: string | number
  withdrawalCountry: {
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }
  totalAmountToSend: string | number
  cashInCountry: {
    label: string
    value: number
    currency?: string
    isO2Code: string
    prefix: string
  }
  withdrawalOption:
    | (WithdrawalOption & {
        currency: string
        currencySign: string
      })
    | null
}

type CalculationFormForHomePageProps = {
  className: string
  onParentSubmit: (
    formData: Omit<CalculationForm, 'totalAmountToSend'>,
    callBack: (value: number, limits: Array<CalculationLimit>) => void
  ) => void
  onContinueClick: (formData: Omit<CalculationForm, 'totalAmountToSend'>) => void
}

const CalculationFormForHomePage = ({
  className,
  onParentSubmit,
  onContinueClick,
}: CalculationFormForHomePageProps) => {
  const withdrawalPaymentOptionsByCountry = useAppSelector(
    state => state.remittance.withdrawalPaymentOptionsByCountry
  )
  const cashInCountryList = useAppSelector(state => state.remittance.cashInCountryList)
  const preset = useAppSelector(state => state.preset.preset)
  const { totalAmountToSend, rate, minAmountToReceive, maxAmountToReceive, isLoading } =
    useAppSelector(state => state.calculation)
  const { userDetails, userLimits } = useAppSelector(state => state.user)

  const [errorVariant, setErrorVariant] = useState<
    '' | 'instance-min-limit' | 'instance-max-limit' | 'user-max-limit' | 'multiples'
  >('')
  const [receiveMethodError, setReceiveMethodError] = useState('')

  const [formData, setFormData] = useState<CalculationForm>({
    receiveAmount: 500,
    withdrawalCountry: {
      label: '',
      value: 0,
      currency: '',
      isO2Code: '',
      prefix: '',
    },
    totalAmountToSend: '',
    cashInCountry: {
      label: '',
      value: 0,
      currency: '',
      isO2Code: '',
      prefix: '',
    },
    withdrawalOption: null,
  })

  const isRateShown = useMemo(() => {
    return (
      window.location.hostname === 'hre.kuva.com' ||
      window.location.hostname === 'remittance.uat.kuvacash.net'
    )
  }, [])

  const withdrawalCountryList = useMemo(
    () => getCountryOptions(withdrawalPaymentOptionsByCountry),
    [withdrawalPaymentOptionsByCountry]
  )
  const cashInCountryListForSelector = useMemo(
    () => getCountryOptions(cashInCountryList),
    [cashInCountryList]
  )

  const withdrawalPaymentProviderOptions = useMemo(
    () =>
      getWithdrawalPaymentOptionsByCountryForHomePage(
        withdrawalPaymentOptionsByCountry,
        formData.withdrawalCountry.value
      ),
    [formData.withdrawalCountry.value, withdrawalPaymentOptionsByCountry]
  )

  useEffect(() => {
    if (errorVariant || !formData.receiveAmount || totalAmountToSend === 0) {
      setFormData(prev => ({
        ...prev,
        ...{ totalAmountToSend: '' },
      }))
      return
    }

    setFormData(prev => ({
      ...prev,
      ...{ totalAmountToSend },
    }))
  }, [errorVariant, formData.receiveAmount, totalAmountToSend])

  const checkReceiveFieldByAmountMultiplesOf = useCallback(
    (value: number | undefined) => {
      if (typeof value === 'undefined') {
        return false
      }

      if (preset?.receiveAmountMultiplesOf && value % preset?.receiveAmountMultiplesOf) {
        setErrorVariant('multiples')
        return false
      }

      setErrorVariant('')
      return true
    },
    [preset?.receiveAmountMultiplesOf]
  )

  const checkReceiveField = useCallback(
    (value: number, limits: Array<CalculationLimit>) => {
      const minAmountToReceive =
        limits.find(limit => limit.period === CalculationLimitPeriod.MinPerTransaction)
          ?.totalLimit || 0
      const maxAmountToReceive =
        limits.find(limit => limit.period === CalculationLimitPeriod.MaxPerTransaction)
          ?.totalLimit || 0

      if (minAmountToReceive !== 0 && value < minAmountToReceive) {
        setErrorVariant('instance-min-limit')
        return false
      }

      if (userDetails?.status === UserStatus.Accepted) {
        const userLimits = limits.filter(
          limit =>
            limit.period !== CalculationLimitPeriod.MinPerTransaction &&
            limit.period !== CalculationLimitPeriod.MaxPerTransaction
        )

        const isExceededUserLimit = userLimits.find(
          limitItem => limitItem.remainingAmount !== -1 && limitItem.remainingAmount < value
        )
        if (isExceededUserLimit) {
          setErrorVariant('user-max-limit')
          return false
        }
      }

      if (maxAmountToReceive !== 0 && value > maxAmountToReceive) {
        setErrorVariant('instance-max-limit')
        return false
      }

      setErrorVariant('')
      return true
    },
    [userDetails?.status]
  )

  const setInitialFormData = useCallback(() => {
    const initialWithdrawalOption = getWithdrawalPaymentOptionsByCountryForHomePage(
      withdrawalPaymentOptionsByCountry,
      withdrawalCountryList[0].value
    )

    const newState = {
      receiveAmount: 500,
      withdrawalCountry: withdrawalCountryList[0],
      cashInCountry: cashInCountryListForSelector[0],
      withdrawalOption: initialWithdrawalOption[0],
    }

    setFormData(prev => ({
      ...prev,
      ...{
        withdrawalCountry: withdrawalCountryList[0],
        cashInCountry: cashInCountryListForSelector[0],
        // withdrawalOption: initialWithdrawalOption[0],
      },
    }))

    onParentSubmit(newState, checkReceiveField)
  }, [
    cashInCountryListForSelector,
    checkReceiveField,
    onParentSubmit,
    withdrawalCountryList,
    withdrawalPaymentOptionsByCountry,
  ])

  useEffect(() => {
    if (withdrawalCountryList.length > 0 && cashInCountryListForSelector.length > 0) {
      setInitialFormData()
    }
  }, [cashInCountryListForSelector.length, setInitialFormData, withdrawalCountryList.length])

  const onChangeReceiveAmount = useCallback(
    (value: number | undefined, modifiedFormData: any, focusCounter?: number) => {
      if (focusCounter === 0) {
        return
      }

      const isValidReceiveField = checkReceiveFieldByAmountMultiplesOf(value)

      if (!isValidReceiveField) {
        return
      }

      if (!modifiedFormData.withdrawalOption) {
        const initialWithdrawalOption = getWithdrawalPaymentOptionsByCountryForHomePage(
          withdrawalPaymentOptionsByCountry,
          formData.withdrawalCountry.value
        )
        const modifiedFormDataWithInitialWithdrawalOption = {
          ...modifiedFormData,
          ...{ withdrawalOption: initialWithdrawalOption[0] },
        }

        onParentSubmit(modifiedFormDataWithInitialWithdrawalOption, checkReceiveField)
        return
      } else {
        return onParentSubmit(modifiedFormData, checkReceiveField)
      }
    },
    [
      checkReceiveField,
      checkReceiveFieldByAmountMultiplesOf,
      formData.withdrawalCountry.value,
      onParentSubmit,
      withdrawalPaymentOptionsByCountry,
    ]
  )

  const debounceOnChangeReceiveAmountFunction = useMemo(
    () => debounce(onChangeReceiveAmount, 700),
    [onChangeReceiveAmount]
  )

  const onChange = useCallback(
    (value: any, name: string, focusCounter?: number) => {
      if (name === 'totalAmountToSend') {
        return
      }

      if (name === 'cashInCountry') {
        const modifiedFormData = {
          ...formData,
          ...{ [name]: value },
        }

        if (!formData.withdrawalOption) {
          const currentWithdrawalOption = getWithdrawalPaymentOptionsByCountryForHomePage(
            withdrawalPaymentOptionsByCountry,
            formData.withdrawalCountry.value
          )

          const modifiedBodyRequest = {
            ...modifiedFormData,
            ...{ withdrawalOption: currentWithdrawalOption[0] },
          }

          if (!errorVariant) {
            onParentSubmit(modifiedBodyRequest, checkReceiveField)
          }
        } else {
          onParentSubmit(modifiedFormData, checkReceiveField)
        }

        setFormData(modifiedFormData)
        return
      }

      if (name !== 'receiveAmount') {
        setErrorVariant('')
      }

      if (name === 'withdrawalOption') {
        setReceiveMethodError('')
      }

      if (name === 'withdrawalCountry') {
        const currentWithdrawalOption = getWithdrawalPaymentOptionsByCountryForHomePage(
          withdrawalPaymentOptionsByCountry,
          value.value
        )

        const modifiedFormData = {
          ...formData,
          ...{ [name]: value, withdrawalOption: null },
        }

        const modifiedBodyRequest = {
          ...modifiedFormData,
          ...{ withdrawalOption: currentWithdrawalOption[0] },
        }

        setFormData(modifiedFormData)
        // if (!errorVariant) {
          onParentSubmit(modifiedBodyRequest, checkReceiveField)
        // }
        return
      }

      if (name === 'receiveAmount') {
        if(value === formData.receiveAmount) {
          return
        }

        const modifiedFormData = { ...formData, ...{ [name]: value } }
        setFormData(modifiedFormData)
        debounceOnChangeReceiveAmountFunction(value, modifiedFormData, focusCounter)
        return
      }

      const modifiedFormData = { ...formData, ...{ [name]: value } }
      setFormData(modifiedFormData)


      // if (!errorVariant) {
        onParentSubmit(modifiedFormData, checkReceiveField)
      // }
    },
    [
      checkReceiveField,
      debounceOnChangeReceiveAmountFunction,
      errorVariant,
      formData,
      onParentSubmit,
      withdrawalPaymentOptionsByCountry,
    ]
  )

  const errorText = useMemo(() => {
    if (errorVariant === 'multiples') {
      return `The amount must be a multiple of ${preset?.receiveAmountMultiplesOf}`
    }

    if (errorVariant === 'instance-min-limit') {
      return `The amount is less than the lower limit ${
        formData.withdrawalOption?.currencySign
          ? formData.withdrawalOption?.currencySign
          : getWithdrawalPaymentOptionsByCountryForHomePage(
              withdrawalPaymentOptionsByCountry,
              formData.withdrawalCountry.value
            )[0].currencySign
      }${minAmountToReceive}`
    }

    if (errorVariant === 'instance-max-limit') {
      return `The amount is greater than the upper limit ${
        formData.withdrawalOption?.currencySign
          ? formData.withdrawalOption?.currencySign
          : getWithdrawalPaymentOptionsByCountryForHomePage(
              withdrawalPaymentOptionsByCountry,
              formData.withdrawalCountry.value
            )[0].currencySign
      }${maxAmountToReceive}`
    }

    if (errorVariant === 'user-max-limit') {
      return (
        <>
          Your daily/monthly limit has been exceeded. More info&nbsp;
          <Link to="/profile" state={{ pathname: '/', tab: 'limits' }}>
            here
          </Link>
        </>
      )
    }
    return ''
  }, [
    errorVariant,
    formData.withdrawalCountry.value,
    formData.withdrawalOption?.currencySign,
    maxAmountToReceive,
    minAmountToReceive,
    preset?.receiveAmountMultiplesOf,
    withdrawalPaymentOptionsByCountry,
  ])

  const onButtonClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault()

      if (!formData.withdrawalOption?.value) {
        setReceiveMethodError('Please, select received method to continue')
        return
      }

      onContinueClick(formData)
    },
    [formData, onContinueClick]
  )

  const currencyPrefixForReceiveAmount = useMemo(() => {
    if (formData.withdrawalOption?.currencySign) {
      return formData.withdrawalOption?.currencySign
    } else {
      const currentCurrencySign =
        getWithdrawalPaymentOptionsByCountryForHomePage(
          withdrawalPaymentOptionsByCountry,
          formData.withdrawalCountry.value
        )[0]?.currencySign || ''

      return currentCurrencySign
    }
  }, [
    formData.withdrawalCountry.value,
    formData.withdrawalOption?.currencySign,
    withdrawalPaymentOptionsByCountry,
  ])

  return (
    <>
      {withdrawalCountryList.length > 0 && cashInCountryListForSelector.length > 0 && (
        <form className={className} id="calculation-form">
          <MoneyInputWithCountrySelector
            selectorName="cashInCountry"
            selectorValue={formData.cashInCountry}
            inputName="totalAmountToSend"
            inputValue={formData.totalAmountToSend}
            options={cashInCountryListForSelector}
            label="You Pay"
            decimalScale={2}
            disabled={true}
            prefix={formData.cashInCountry.prefix}
            onChange={onChange}
            shouldCleanInputWhenFirstClick={false}
          />
          <p
            className="send-block__text"
            style={{ padding: '5px 16px 0', margin: 0, textAlign: 'end' }}
          >
            *Cost inc fees
          </p>
          <MoneyInputWithCountrySelector
            selectorName="withdrawalCountry"
            selectorValue={formData.withdrawalCountry}
            inputName="receiveAmount"
            inputValue={formData.receiveAmount}
            options={withdrawalCountryList}
            label="You Receive"
            decimalScale={0}
            disabled={false}
            prefix={currencyPrefixForReceiveAmount}
            onChange={onChange}
            shouldCleanInputWhenFirstClick={true}
            errorVariant={errorVariant}
          />
          {errorVariant ? (
            <span className="form-input-error">{errorText}</span>
          ) : (
            <p className="send-block-amount-text">
              {preset?.receiveAmountMultiplesOf
                ? `The amount must be a multiple of ${preset?.receiveAmountMultiplesOf}`
                : ''}
            </p>
          )}
          <div className="send-block-row">
            <div className="send-block__separator"></div>
            {!isRateShown && (
              <p className="send-block__text">
                Exchange Rate: 1.00 {formData.cashInCountry.currency} = {rate.toFixed(4)}{' '}
                {formData.withdrawalOption?.currency
                  ? formData.withdrawalOption.currency
                  : getWithdrawalPaymentOptionsByCountryForHomePage(
                      withdrawalPaymentOptionsByCountry,
                      formData.withdrawalCountry.value
                    )[0]?.currency || ''}
              </p>
            )}
            <p className="send-block__text">
              We use a real-time rate, for best prices. This may update prior to you booking your
              transfer.
            </p>
            {/* {preset?.additionalLogo && (
                  <div className="poweredBy">
                    <img alt="Additional Logo" src={preset?.additionalLogo} />
                  </div>
                )} */}
          </div>
          <CustomSelector
            options={withdrawalPaymentProviderOptions}
            value={formData.withdrawalOption}
            onChange={onChange}
            name="withdrawalOption"
            styles={receiveMethodSelectorConfig.selectorStyles}
            labelName="Receive Method"
            error={receiveMethodError}
          />
          <div className="btn-wrapper">
            <button
              className="btn btn-primary btn-additional"
              form="calculation-form"
              type="submit"
              disabled={
                Boolean(errorVariant) ||
                isLoading ||
                !formData.receiveAmount ||
                Boolean(receiveMethodError)
              }
              onClick={onButtonClick}
            >
              Continue
            </button>
          </div>
          {preset?.sendMoneySectionText && (
            <div className="send-block__additional">
              <Icon.LightningChargeFill />
              {preset?.sendMoneySectionLink ? (
                <a href={preset?.sendMoneySectionLink}>{preset?.sendMoneySectionText}</a>
              ) : (
                <p>{preset?.sendMoneySectionText}</p>
              )}
            </div>
          )}
          {preset?.additionalLogo && (
            <div className="poweredBy">
              <img alt="Additional Logo" src={preset?.additionalLogo} />
            </div>
          )}
        </form>
      )}
    </>
  )
}

export default CalculationFormForHomePage
