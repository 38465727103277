import {
  Dispatch,
  LegacyRef,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getShortDate } from '../../helpers/dateFormat'
import * as Flags from 'country-flag-icons/react/3x2'
import { Icon } from '../icons'
import Tooltip from 'rc-tooltip'
import { useLocation, useNavigate } from 'react-router-dom'
import { recipientDetailsInLocalStorage } from '../../helpers/recipientData'
import { calculationHelper } from '../../helpers/calculationData'
import Modal from '../Modal'
import { transactionStatusConfig } from '../../helpers/configs/transactionConfig'
import isNull from 'lodash/isNull'
import { AmlStatus } from '../../interfaces/user'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { userActions } from '../../redux/action-creators/userActions'
import {
  PaymentIntentDetails,
  ShortTransactionDetails,
  TransactionStatus,
} from '../../interfaces/remittanceType'
import { remittanceService } from '../../services/remittanceService'
import { BeneficiaryType } from '../../interfaces/beneficiary'

/* 
TO DO: Remove conditions, requests and paymentIntentSwitcher variable 
after full integration of payment intents
*/

const paymentIntentSwitcher = process.env.REACT_APP_PAYMENT_INTENT_SWITCHER

type TransactionItemProps = {
  transaction: ShortTransactionDetails
  reference: LegacyRef<any>
  searchedTxValue: string
  setIsOpenedSomeTooltip: Dispatch<SetStateAction<boolean>>
  setIsOpenVerificationModal: Dispatch<SetStateAction<boolean>>
}
const TransactionItem = ({
  transaction,
  reference,
  searchedTxValue,
  setIsOpenedSomeTooltip,
  setIsOpenVerificationModal,
}: TransactionItemProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const flowList = useAppSelector(state => state.preset.availableFlow)

  const [hover, setHover] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const isMobile = useMemo(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
  }, [])

  const currentTransactionStatus = useMemo(() => {
    return transactionStatusConfig.find(
      transactionStatus => transactionStatus.type === transaction.status
    )
  }, [transaction.status])

  const FlagComponent =
    transaction.recipient.type === BeneficiaryType.Individual
      ? Flags[`${transaction.recipient.countryIso}`]
      : Flags['ZW']

  useEffect(() => {
    if (!hover) {
      setShowToolTip(false)
    }
  }, [hover])

  const handleMouseIn = () => {
    setHover(true)
    setIsOpenedSomeTooltip(true)
  }

  const handleMouseOut = () => {
    setHover(false)
    setIsOpenedSomeTooltip(false)
  }

  const onViewDetails = (event: SyntheticEvent) => {
    event.stopPropagation()
    navigate(`/transaction/${transaction.id}`, { state: { searchedTxValue } })
  }

  const onRecipientDetails = (event: SyntheticEvent) => {
    event.stopPropagation()
    navigate(`/transaction/${transaction.id}/beneficiary`, { state: { searchedTxValue } })
  }

  const moveAfterAmlCheck = useCallback(
    async (userAmlStatus: AmlStatus | undefined) => {
      if (userAmlStatus === AmlStatus.Verified) {
        if (paymentIntentSwitcher === 'true') {
          const from = pathname
          const tx = (await remittanceService.getTransaction(
            transaction.id
          )) as PaymentIntentDetails

          recipientDetailsInLocalStorage.saveRecipientDetails(tx.recipient)
          calculationHelper.setCalculationData(transaction.amountToReceive)
          navigate('/transaction/new', { state: { searchedTxValue, from } })
        } else {
          if (transaction.recipient.isDeleted) {
            setIsOpenModal(true)
          } else {
            const from = pathname

            recipientDetailsInLocalStorage.saveRecipientDetails(transaction.recipient)
            calculationHelper.setCalculationData(transaction.amountToReceive)
            navigate('/transaction/new', { state: { searchedTxValue, from } })
          }
        }
      } else {
        setIsOpenVerificationModal(true)
      }
    },
    [
      navigate,
      pathname,
      searchedTxValue,
      setIsOpenVerificationModal,
      transaction.amountToReceive,
      transaction.id,
      transaction.recipient,
    ]
  )

  const onSendAgain = (event: SyntheticEvent) => {
    event.stopPropagation()

    dispatch(userActions.getUserAmlList(flowList, moveAfterAmlCheck))
  }

  const openTooltip = (event: SyntheticEvent) => {
    event.stopPropagation()
    setShowToolTip(true)
  }

  // const showSendAgainButton =
  //   transaction.status === TransactionStatus.Cancelled ||
  //   transaction.status === TransactionStatus.Redeemed

  const showSendAgainButton = false

  const modalConfirmFunction = () => {
    setIsOpenModal(false)
    navigate('/beneficiaries/select-beneficiary')
    calculationHelper.setCalculationData(transaction.amountToReceive)
  }

  const referenceCode = useMemo(() => {
    if (
      isNull(transaction.digitalReference) ||
      transaction.status === TransactionStatus.Ordered ||
      transaction.status === TransactionStatus.Transferred ||
      transaction.status === TransactionStatus.Cancelled ||
      transaction.status === TransactionStatus.Expired
    ) {
      return '--- ---- ----'
    } else {
      return transaction.digitalReference
    }
  }, [transaction.digitalReference, transaction.status])

  return (
    <>
      <div
        ref={reference}
        onMouseOver={handleMouseIn}
        onMouseOut={handleMouseOut}
        onClick={onViewDetails}
        className="table-row"
        style={{ position: 'relative' }}
      >
        <div className="table-item transaction-item">{getShortDate(transaction.bookedDate)}</div>
        {transaction.recipient.type === BeneficiaryType.Individual ? (
          <div className="table-item transaction-item underline medium">
            <span
              className="name"
              onClick={onRecipientDetails}
              title={`${transaction.recipient.firstName} ${transaction.recipient.lastName}`}
            >
              {transaction.recipient.firstName} {transaction.recipient.lastName}
            </span>
          </div>
        ) : (
          <div className="table-item transaction-item underline medium">
            <span
              className="name"
              onClick={onRecipientDetails}
              title={`${transaction.recipient?.companyName}`}
            >
              {transaction.recipient?.companyName}
            </span>
          </div>
        )}
        <div className="table-item transaction-item row">
          {transaction.recipient.type === BeneficiaryType.Individual ? (
            transaction.recipient.countryIso && (
              <>
                <div style={{ width: '28px', paddingRight: '5px' }}>
                  <FlagComponent />
                </div>
                <div>{transaction.recipient.countryIso}</div>
              </>
            )
          ) : (
            <>
                <div style={{ width: '28px', paddingRight: '5px' }}>
                  <FlagComponent />
                </div>
                <div>ZW</div>
              </>
          )}
        </div>
        <div className="table-item transaction-item">{transaction.receiveCurrency}</div>
        <div className="table-item transaction-item bold">{transaction.receiveCurrencySign}{transaction.amountToReceive}</div>
        <div className="table-item transaction-item bold">{referenceCode}</div>
        <div
          className={`table-item transaction-item row small ${currentTransactionStatus?.className}`}
        >
          {currentTransactionStatus?.icon}
          {currentTransactionStatus?.label}
        </div>

        {(hover || isMobile) && (
          <div className="table-item show-more">
            {showSendAgainButton && (
              <div>
                <button
                  className="btn btn-primary btn-additional hide-mobile"
                  onClick={onSendAgain}
                >
                  Send Again
                </button>
              </div>
            )}

            <Tooltip
              visible={showToolTip}
              placement="left"
              trigger={['click']}
              mouseLeaveDelay={0}
              getTooltipContainer={elem => elem}
              showArrow={false}
              overlay={
                <div>
                  {showSendAgainButton && (
                    <button className="show-mobile" onClick={onSendAgain}>
                      Send Again
                    </button>
                  )}
                  <button onClick={onViewDetails}>View Details</button>
                </div>
              }
            >
              <button className="circle-with-dots" onClick={openTooltip}>
                <Icon.CircleWithDots />
              </button>
            </Tooltip>
          </div>
        )}
      </div>
      <Modal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        confirmFunction={modalConfirmFunction}
        modalHeader="The Beneficiary has been deleted"
        modalBody='The beneficiary has been deleted, so you can select another one and click the "Send Money" button.'
        modalType="informationModal"
      />
    </>
  )
}

export default TransactionItem
