import * as yup from 'yup'
import { BeneficiaryType, GetBeneficiaryFieldsConfigResponse } from '../../interfaces/beneficiary'
import { documentTypeOptionList } from '../configs/optionConfigs'
import { checkForDateExistence } from '../utils'

const beneficiaryScheme = (config: GetBeneficiaryFieldsConfigResponse | null) => {
  let validationSchema = yup.object()
  const docList = documentTypeOptionList(config)

  if (config) {
    if (config.Individual.FirstName.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          firstName: yup
            .string()
            .typeError('Please, enter first name')
            .matches(
              /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
              'Please, use only letters'
            )
            .required('Please, enter first name'),
        })
      )
    }

    if (config.Individual.LastName.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          lastName: yup
            .string()
            .typeError('Please, enter last name')
            .matches(
              /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-.`‘’']{0,1}[\s]{0,1})+[a-zA-Z.]*[\s]*$/,
              'Please, use only letters'
            )
            .required('Please, enter last name'),
        })
      )
    }

    if (config.Individual.PhoneNumber.visible) {
      validationSchema = validationSchema.concat(
        yup.object({ phoneNumber: yup.string().required('Please, enter a valid phone number') })
      )
    }

    if (config.Individual.DateOfBirth.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          dateOfBirth: yup
            .string()
            .typeError('Please, enter date of birth')
            .test('validateDate', 'Please, enter a valid date of birth', checkForDateExistence)
            .required('Please, enter date of birth'),
        })
      )
    }

    if (config.Individual.Passport.visible || config.Individual.DriversLicense.visible || config.Individual.NationalId.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          documentType: yup
            .string()
            .typeError('Please, select document type')
            .required('Please, select document type'),
        })
      )
    }

    if (config.Individual.DocumentNumber.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          documentNumber: yup
            .string()
            .typeError('Please, enter ID number')
            .trim()
            .min(7, `Please enter a complete document number.`)
            .matches(
              /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-]{0,1}[\s]{0,1})+[a-zA-Z0-9]*[\s]*$/,
              'Please, use only letters and numbers'
            )
            .required('Please, enter ID number'),
        })
      )
    }

    if (config.Individual.DocumentIssuingCountry.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          documentCountryIso: yup
            .string()
            .typeError('Please, select recipient ID country of issue')
            .required('Please, select recipient ID country of issue'),
        })
      )
    }

    if (config.Individual.AddressCity.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          city: yup
            .string()
            .typeError('Please, enter city')
            .matches(
              /^[\s]*([a-zA-Z]{1,}[\s]{0,1}[-]{0,1}[\s]{0,1}[a-zA-Z]{1,})+[\s]*$/,
              'Please, use only letters'
            )
            .required('Please, enter city'),
        })
      )
    }

    if (config.Individual.AddressStreet.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          street: yup
            .string()
            .typeError('Please, enter street')
            .matches(
              /^[\s]*([a-zA-Z0-9]{1,}[\s]{0,1}[-,./]{0,2}[\s]{0,1})+[\s]*$/,
              "Please, don't use special characters"
            )
            .required('Please, enter street'),
        })
      )
    }

    if (config.Individual.AddressCountry.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          countryId: yup
            .string()
            .typeError('Please, select country')
            .required('Please, select country'),
        })
      )
    }

    if (docList.length > 0) {
      validationSchema = validationSchema.concat(
        yup.object({
          documentType: yup
            .string()
            .typeError('Please, select document type')
            .required('Please, select document type'),
        })
      )
    }

    if (config.Individual.Gender.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          gender: yup
            .string()
            .typeError('Please, select gender of recipient')
            .required('Please, select gender of recipient'),
        })
      )
    }

    if (config.Individual.AccountNumber.visible &&
      config.Individual.BankName.visible &&
      config.Individual.NotificationEmail.visible &&
      config.Individual.Reference.visible) {
      validationSchema = validationSchema.concat(
        yup.object({
          accountNumber: yup.string().when('bankId', (bankId, field) => bankId && bankId !== '-'
            ? field.trim().typeError('Please, enter account number or merchant code')
              .matches(/^[0-9a-zA-Z]*$/, 'Please, enter a valid account number or merchant code')
              .min(2, `Please, enter a complete account number or merchant code.`)
              .required('Please, enter account number or merchant code')
            : field
          ),
          reference: yup.string().when('bankId', (bankId, field) => bankId && bankId !== '-'
            ? field.trim()
              .typeError('Please, enter beneficiary reference')
              .required('Please, enter beneficiary reference')
            : field
          ),
          notificationEmail: yup.string().when('bankId', (bankId, field) => bankId && bankId !== '-'
            ? field.trim().typeError('Please, enter beneficiary email')
              .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please, enter a valid beneficiary email')
              .required('Please, enter beneficiary email')
            : field
          ),
        })
      )
    }

  }

  return validationSchema
}

const beneficiaryLookUpScheme = yup.object({
  nationalId: yup.string().when('type', (type, field) => {
    const numericBeneficiaryType =
      typeof type === 'number' ? type : Number(type)

    return numericBeneficiaryType === BeneficiaryType.Individual
      ? field.trim().matches(/^[0-9a-zA-Z-]*$/, 'Please, enter a valid National ID Number')
      : field
  }),
})

const companyBeneficiaryScheme = (config: GetBeneficiaryFieldsConfigResponse | null) => {
  let validationSchema = yup.object()

  if (config) {
    if (config.Company.CompanyName.visible) {
      validationSchema = validationSchema.concat(yup.object({
        companyName: yup.string().trim()
          .typeError('Please, enter company name')
          .matches(
            /^([a-zA-Z0-9]{1,}[\s]{0,1}[-]{0,1}[\s]{0,1})+[a-zA-Z0-9]*$/,
            'Please, enter a valid company name'
          )
          .required('Please, enter company name'),

      }))
    }
    if (config.Company.AccountNumber.visible &&
      config.Company.BankName.visible &&
      config.Company.NotificationEmail.visible &&
      config.Company.Reference.visible) {
      validationSchema = validationSchema.concat(yup.object({
        bankId: yup.string().typeError('Please, select a Bank').required('Please, select a Bank'),
        accountNumber: yup.string().trim()
          .typeError('Please, enter account number or merchant code')
          .matches(/^[0-9a-zA-Z]*$/, 'Please, enter a valid account number or merchant code')
          .min(2, `Please, enter a complete account number or merchant code.`)
          .required('Please, enter account number or merchant code'),
        reference: yup.string().trim()
          .typeError('Please, enter beneficiary reference')
          .required('Please, enter beneficiary reference'),
        notificationEmail: yup.string().trim()
          .typeError('Please, enter beneficiary email')
          .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Please, enter a valid beneficiary email')
          .required('Please, enter beneficiary email')
      }))
    }
  }

  return validationSchema
}

export const beneficiaryYupSchemes = {
  beneficiaryScheme,
  beneficiaryLookUpScheme,
  companyBeneficiaryScheme,
}
