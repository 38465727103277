import { Navigate, Route, Routes } from 'react-router-dom'

import HelpSupportPage from '../pages/helpPage'
import MobileVerificationPage from '../pages/MobileVerificationPage'
import PayForYourTransferPage from '../pages/PayForYourTransferPage'
import ProfilePage from '../pages/ProfilePage'
import MaintenancePage from '../pages/MaintenancePage'
import ConfirmEmailPage from '../pages/ConfirmEmailPage'
import PendingVerificationPage from '../pages/singUpPages/PendingVerificationPage'
import SumSubPage from '../pages/singUpPages/SumSubPage'
import UserAddressPage from '../pages/singUpPages/UserAddressPage'
import UserDetailsPage from '../pages/singUpPages/UserDetailsPage'
import Layout from './Layout'
import LoggedInPrivateRoute from './LoggedInPrivateRoute'
import NonLoggedInPrivateRoute from './NonLoggedInPrivateRoute'
import StartPage from '../pages/StartPage'
import NewTransactionPage from '../pages/NewTransactionPage'
import PastTransactionsPage from '../pages/PastTransactionsPage'
import TransactionDetailsPage from '../pages/TransactionDetailsPage'
import BeneficiariesPage from '../pages/BeneficiariesPage'
import CalculationPage from '../pages/CalculationPage'
import BeneficiaryPage from '../pages/BeneficiaryPage'
import TransactionRecipientDetailsPage from '../pages/TransactionRecipientDetailsPage'
import EmailVerificationPendingPage from '../pages/singUpPages/EmailVerificationPendingPage'
import VerificationCardChallenge from '../components/transactions/cardPayment/VerificationCardChallenge'
import AgentsLocationPage from '../pages/AgentsLocationPage'
import FailedPaymentPage from '../pages/FailedPaymentPage'
// import FaqPage from '../pages/FaqPage'

// var for starting countDown timer: "REACT_APP_COUNTDOWN_DATE": "2022-08-05 12:45:00.000 GMT+0300"
const isCountDownShow =
  process.env.REACT_APP_COUNTDOWN_DATE! &&
  +new Date(process.env.REACT_APP_COUNTDOWN_DATE!) > +new Date()

const RouteList = () => {
  return (
    <Routes>
      {isCountDownShow ? (
        // {true ? (
        <>
          <Route path="/" element={<StartPage />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Layout />}>
            <Route index element={<CalculationPage />} />
            <Route path="/" element={<LoggedInPrivateRoute />}>
              <Route path="/pending" element={<PendingVerificationPage />} />
              <Route path="/beneficiaries" element={<BeneficiariesPage />} />
              <Route path="/beneficiaries/select-beneficiary" element={<BeneficiariesPage />} />
              <Route path="/beneficiary/new" element={<BeneficiaryPage />} />
              <Route path="/beneficiary/:beneficiaryId" element={<BeneficiaryPage />} />
              <Route path="/past-transactions" element={<PastTransactionsPage />} />
              <Route path="/transaction/new" element={<NewTransactionPage />} />
              <Route path="/transaction/:id" element={<TransactionDetailsPage />} />
              <Route
                path="/transaction/:id/beneficiary"
                element={<TransactionRecipientDetailsPage />}
              />

              <Route path="/transaction/:id/payment-details" element={<PayForYourTransferPage />} />
              <Route
                path="/transaction/:id/payment-details/failed"
                element={<FailedPaymentPage type='failed'/>}
              />
               <Route
                path="/transaction/:id/payment-details/aborted"
                element={<FailedPaymentPage type='aborted'/>}
              />

              <Route path="/verification" element={<SumSubPage />} />
              <Route path="/sing-up/user-details" element={<UserDetailsPage />} />
              <Route path="/sing-up/user-address" element={<UserAddressPage />} />
              <Route path="/profile" element={<ProfilePage />}></Route>
              <Route
                path="/email-verification-pending"
                element={<EmailVerificationPendingPage />}
              />
            </Route>
            <Route
              path="/login"
              element={
                <NonLoggedInPrivateRoute>
                  <MobileVerificationPage />
                </NonLoggedInPrivateRoute>
              }
            />
            <Route path="/confirm-email" element={<ConfirmEmailPage />} />
            <Route path="/maintenance" element={<MaintenancePage />} />
            <Route path="*" element={<Navigate replace to="/" />} />
            <Route path="/help" element={<HelpSupportPage />} />
           

            {/* <Route path="/faq" element={<FaqPage />} /> */}
            <Route path="/agents-location" element={<AgentsLocationPage />} />
          </Route>
          <Route path="/verification-card-payment" element={<VerificationCardChallenge />} />
        </>
      )}
    </Routes>
  )
}

export default RouteList
