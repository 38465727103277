import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { authActions } from '../../redux/action-creators/authActions'
import { errorActions } from '../../redux/action-creators/errorActions'
import { presetActions } from '../../redux/action-creators/presetActions'
import { Icon } from '../icons'

const ErrorModal = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { userDetails } = useAppSelector(state => state.user)
  const { errorCode, id } = useAppSelector(state => state.error)

  const [isOpenModal, setIsOpenModal] = useState(false)

  const openModal = useCallback(async () => {
    if (errorCode === '4000000211') {
      await dispatch(presetActions.getAvailableFlow())
    }

    setIsOpenModal(true)
  }, [dispatch, errorCode])

  useEffect(() => {
    if (errorCode) {
      openModal()
    }
  }, [errorCode, openModal])

  const closeModal = useCallback(() => {
    dispatch(errorActions.clearError())
    // dispatch(authActions.removeMobileNumberFromState())
    setIsOpenModal(false)
  }, [dispatch])

  const confirmFunction = useCallback(() => {
    if (errorCode === '403' && userDetails) {
      dispatch(authActions.logOutAction())
      dispatch(authActions.removeMobileNumberFromState())
    }
    if (errorCode === '40400000067') {
      navigate('/beneficiaries')
    }

    if (
      errorCode === '4001000035' ||
      errorCode === '4001000076' ||
      errorCode === '4001000077' ||
      (errorCode <= '4001000071' && errorCode >= '4001000063') ||
      errorCode === '4000000241'
    ) {
      id
        ? navigate(`/beneficiary/${id}`, { state: { editMode: true } })
        : navigate(`/beneficiaries`)
    }

    if (errorCode === '4000000199' || errorCode === 'creation-tx') {
      navigate('/past-transactions')
    }

    if (errorCode === '4000000211') {
      navigate('/verification')
    }

    if (errorCode === '5000000194') {
      navigate('/')
    }

    closeModal()
  }, [closeModal, dispatch, errorCode, id, navigate, userDetails])

  const helpUrl = useMemo(() => {
    // if (window.location.hostname === 'localhost') {
    //   return 'https://wpdev04.newlinetechno.net/contact/'
    // }
    if (window.location.hostname === 'uat.zikicash.com') {
      return 'https://wpdev04.newlinetechno.net/contact/'
    }
    if (window.location.hostname === 'send.zikicash.com') {
      return 'https://zikicash.com/contact'
    }

    return '/help'
  }, [])

  const modalBody = useMemo(() => {
    const isInternalServerError = errorCode.startsWith('500')

    if (errorCode === '403') {
      return (
        <>
          Your account has been blocked - please contact&nbsp;
          {helpUrl === '/help' ? (
            <Link to="/help" onClick={closeModal}>
              support
            </Link>
          ) : (
            <a href={helpUrl} rel="noreferrer">
              support
            </a>
          )}
          {/* <Link to="/help" onClick={closeModal}>
            support
          </Link> */}
        </>
      )
    }
    if (errorCode === '40400000067') {
      return <>The beneficiary has been deleted, so you can select another one.</>
    }
    if (errorCode === '4000000199') {
      return (
        <>
          Your daily/monthly limit has been exceeded. More info&nbsp;
          <Link
            to="/profile"
            state={{ pathname: '/past-transactions', tab: 'limits' }}
            onClick={closeModal}
          >
            here
          </Link>
        </>
      )
    }
    if (
      errorCode === '4001000035' ||
      errorCode === '4001000076' ||
      errorCode === '4001000077' ||
      (errorCode <= '4001000071' && errorCode >= '4001000063') ||
      errorCode === '4000000241'
    ) {
      return <>Please update information about the selected beneficiary.</>
    }

    if (errorCode === 'creation-tx') {
      return <>An error has occurred. Please, create transaction again</>
    }

    if (errorCode === '4000000211') {
      return <>For security reasons, please, complete the Identity Verification.</>
    }

    if (errorCode === '5000000194' || errorCode === '5000000198' || isInternalServerError) {
      return <>Something went wrong, try again later.</>
    }

    if (errorCode === '4000000253') {
      return (
        <>
          Beneficiary details were found in the Sanction list. You can’t send transfer to this
          Beneficiary
        </>
      )
    }

    if (errorCode === '4000000254') {
      return (
        <>
          The details of the beneficiary added on your profile are going through a review process
          before money can be sent to the beneficiary. You will be advised once the review process
          (takes up to 24 hours) is completed. If the need to send funds is urgent, please add an{' '}
          <b>alternative beneficiary</b> and send funds to them, they will receive the funds
          instantly
        </>
        // <>
        //   Your beneficiary is temporary disabled. Please, contact&nbsp;
        //   <Link to="/help" onClick={closeModal}>
        //     our support
        //   </Link>
        // </>
      )
    }

    if (errorCode === '4000000264') {
      return <>Unable to complete transaction, please contact support.</>
    }
    if(errorCode === '4000000265') {
      return <>The transaction amount is greater than the maximum allowed. Please try to create a transaction with a smaller value</>
    }
  }, [closeModal, errorCode, helpUrl])

  return (
    <ReactModal
      onRequestClose={confirmFunction}
      shouldCloseOnOverlayClick={true}
      className="modal-window"
      overlayClassName="modal-overlay"
      isOpen={isOpenModal}
      ariaHideApp={false}
    >
      <div className="modal-header">
        <Icon.RedCircle />
        <h2>Error</h2>
      </div>
      <div className="modal-body md">
        <h5 className="t-center mt-20 mb-20" style={{ fontWeight: '400' }}>
          {modalBody}
        </h5>
        <div className="btn-wrapper center">
          <button className="btn btn-primary btn-sm" onClick={confirmFunction}>
            {userDetails && errorCode === '403' ? 'Log Out' : 'OK'}
          </button>
        </div>
      </div>
    </ReactModal>
  )
}

export default ErrorModal
