import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { AmlStatus } from '../../interfaces/user'
import { authActions } from '../../redux/action-creators/authActions'
import BuildVersion from '../BuildVersion'
import { Icon } from '../icons'

const MobileMenu: React.FC = props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const userDetails = useAppSelector(state => state.user.userDetails)
  const preset = useAppSelector(state => state.preset.preset)

  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)

  const mobileMenuRef = useRef<HTMLDivElement>(null)
  // const isVerified = userDetails?.status === 20
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

  const isRedirectChange = useMemo(
    () =>
      // window.location.hostname === 'localhost' ||
      window.location.hostname === 'remittance.dev.kuvacash.net' ||
      window.location.hostname === 'send.zikicash.com',
    []
  )

  const closeMobileMenu = useCallback(() => {
    setIsOpenMobileMenu(false)
  }, [])

  const onClickAway = useCallback(
    (event: any) => {
      if (!mobileMenuRef.current?.contains(event.target)) {
        event.stopPropagation()
        closeMobileMenu()
      }
    },
    [closeMobileMenu]
  )

  useEffect(() => {
    if (isOpenMobileMenu) {
      document.addEventListener('click', onClickAway, { capture: true })
      document.body.classList.add('overflow-hidden')
      return () => {
        document.body.classList.remove('overflow-hidden')
        document.removeEventListener('click', onClickAway, { capture: true })
      }
    }
  }, [isOpenMobileMenu, onClickAway])

  const onToggleMenu = () => {
    setIsOpenMobileMenu(isOpenMobileMenu => !isOpenMobileMenu)
  }

  const onLogOut = async () => {
    await dispatch(authActions.logOutAction())
    // dispatch(calculationActions.postCalculationAction(defaultCalculationBody))
    navigate('/')
  }

  const onSingUp = () => {
    navigate('/login', { state: { from: 'sing-up' } })
  }

  const helpUrl = useMemo(() => {
    // if (window.location.hostname === 'localhost') {
    //   return 'https://wpdev04.newlinetechno.net/contact/'
    // }
    if (window.location.hostname === 'uat.zikicash.com') {
      return 'https://wpdev04.newlinetechno.net/contact/'
    }
    if (window.location.hostname === 'send.zikicash.com') {
      return 'https://zikicash.com/contact'
    }

    return '/help'
  }, [])

  return (
    <>
      <button
        onClick={onToggleMenu}
        className={`profile-btn${isOpenMobileMenu ? ' active' : ''}`}
        aria-controls="primary-menu"
        aria-expanded="false"
      >
        <Icon.User />
        <span></span>
      </button>

      <div className={`profile-block-wrapper${isOpenMobileMenu ? ' active' : ''}`}>
        <div
          ref={mobileMenuRef}
          onClick={closeMobileMenu}
          className={`profile-block${isOpenMobileMenu ? ' active' : ''}`}
        >
          {userDetails === null ? (
            <div className="profile">
              <div className="profile-home">
                {isRedirectChange ? (
                  <a href="https://zikicash.com/" rel="noreferrer">
                    <Icon.ArrowLeftBack />
                    Home
                  </a>
                ) : (
                  <Link to="/">
                    <Icon.ArrowLeftBack />
                    Home
                  </Link>
                )}
              </div>
              <nav className="profile-general-menu">
                {helpUrl === '/help' ? (
                  <NavLink to="/help">Contact Us</NavLink>
                ) : (
                  <a href={helpUrl} rel="noreferrer">
                    Contact Us
                  </a>
                )}
                {/* <NavLink to="/help">Contact Us</NavLink> */}
                {/* <NavLink to='/faq'>FAQ</NavLink> */}
              </nav>
              <button onClick={onSingUp} className="btn btn-logOut">
                Sign Up | Log In
              </button>
            </div>
          ) : (
            <>
              <div className="profile">
                <div className="profile-home">
                  {isRedirectChange ? (
                    <a href="https://zikicash.com/" rel="noreferrer">
                      <Icon.ArrowLeftBack />
                      Home
                    </a>
                  ) : (
                    <Link to="/">
                      <Icon.ArrowLeftBack />
                      Home
                    </Link>
                  )}
                </div>
                <nav className="profile-general-menu">
                  {isRedirectChange ? (
                    <a href="https://zikicash.com/" rel="noreferrer">
                      Home
                    </a>
                  ) : (
                    <NavLink to="/" className="hide-mobile">
                      Home
                    </NavLink>
                  )}
                  <NavLink to="/profile" className="hide-mobile">
                    My Account
                  </NavLink>
                  {helpUrl === '/help' ? (
                    <NavLink to="/help" state={{ pathname }}>
                      Contact Us
                    </NavLink>
                  ) : (
                    <a href={helpUrl} rel="noreferrer">
                      Contact Us
                    </a>
                  )}
                  {/* <NavLink to="/help" state={{ pathname }}>
                    Contact Us
                  </NavLink> */}
                  {/* <NavLink to='/faq'>FAQ</NavLink> */}
                </nav>
                <div className="profile-details">
                  <Link className="profile-name" to="/profile" state={{ pathname }}>
                    Welcome
                    {userDetails?.firstName && `, ${userDetails?.firstName}`}
                  </Link>
                  {userDetails && <span className="profile-tel">{userDetails?.phoneNumber}</span>}
                  {userAmlStatus === AmlStatus.Verified && (
                    <div className="fz-xSmall verified-success">
                      <Icon.SuccessTick /> Verified
                    </div>
                  )}
                  <div className="profile-arrow show-mobile">
                    <Link className="profile-name t-center" to="/profile" state={{ pathname }}>
                      <Icon.ArrowRightBack />
                    </Link>
                  </div>
                </div>
                <button onClick={onLogOut} className="btn btn-logOut">
                  <Icon.Door /> Log Out
                </button>
              </div>

              <div className="profile-links">
                <a href={preset?.termAndConditionsPdf} target="_blank" rel="noreferrer">
                  Terms and Conditions
                </a>
                <strong> - </strong>
                <a href={preset?.privacyPolicyPdf} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                <strong> - </strong>
                <a href={preset?.websiteTermsOfUsePdf} target="_blank" rel="noreferrer">
                  Website Terms of Use
                </a>
              </div>
            </>
          )}
          <BuildVersion className="build-version-mobile" />
        </div>
      </div>
    </>
  )
}

export default MobileMenu
