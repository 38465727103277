import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import PinInput from 'react-pin-input'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { UserDetails, UserStatus } from '../../interfaces/user'
import { beneficiaryService } from '../../services/beneficiaryService'
import isEmpty from 'lodash/isEmpty'
import CalculationSection from '../calculation/CalculationSection'
import { authActions } from '../../redux/action-creators/authActions'
import { isUserDataExist } from '../../helpers/userHelper'
import { calculationHelper } from '../../helpers/calculationData'
import { localStorageHelper } from '../../helpers/useLocalStorage'

type CodeVerificationProps = {
  from: string
}

const CodeVerification = ({ from }: CodeVerificationProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { phoneNumber, errorCode, isLoading } = useAppSelector(state => state.auth)
  const [errorField, setErrorField] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [receiveAmount, setReceiveAmount] = useState<number | undefined | string>()
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  let codeInputs: PinInput | null = null

  const [, updateState] = useState<any>()
  const forceUpdate = useCallback(() => updateState({}), [])

  const receiveAmountFromLocalStorage = calculationHelper.getCalculationData()
  const receivePaymentData = localStorageHelper.getData('receiveMethod')

  useEffect(() => {
    if (errorCode === '4000000135') {
      setErrorField('Code is invalid')
      codeInputs && codeInputs.clear()
    }
  }, [codeInputs, errorCode])

  useEffect(() => {
    if (receiveAmountFromLocalStorage) {
      setReceiveAmount(+receiveAmountFromLocalStorage)
    }
  }, [receiveAmountFromLocalStorage])

  useEffect(() => {
    codeInputs && codeInputs.focus()
    forceUpdate()
  }, [codeInputs, forceUpdate])

  const isDisabledButton = useMemo(
    () => Boolean(code.length < 6) || isDisabledBtn || Boolean(errorField) || isLoading,
    [code.length, errorField, isDisabledBtn, isLoading]
  )

  const onCodeChange = (value: string) => {
    setCode(value)
    setErrorField('')
  }

  const onComplete = (value: string) => {
    setCode(value)
    setErrorField('')
  }

  const makeRedirect = useCallback(
    async (userDetails: UserDetails) => {
      const isFullUserData = isUserDataExist(userDetails)
      const isEmailConfirmed = userDetails.isEmailConfirmed

      const offset = 0
      const step = 1
      const beneficiaries = await beneficiaryService.getBeneficiaries(offset, step)

      if (userDetails.status === UserStatus.Accepted) {
        if (
          isFullUserData &&
          isEmailConfirmed &&
          (from === 'continue-transaction' || receiveAmount)
        ) {
          if (isEmpty(beneficiaries.items)) {
            navigate('/beneficiary/new', { state: { from } })
            return
          } else {
            navigate('/beneficiaries/select-beneficiary', { state: { from } })
            return
          }
        }

        if (isFullUserData && !isEmailConfirmed) {
          navigate('/email-verification-pending', { state: { from } })
          return
        }

        if (!isFullUserData) {
          navigate('/sing-up/user-details', { state: { from } })
          return
        }
      }

      navigate('/')
    },
    [from, navigate, receiveAmount]
  )

  const onVerify = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()

      if (code.length === 6) {
        if (from === 'continue-transaction' || (receiveAmount && +receiveAmount)) {
          typeof receiveAmount === 'number' &&
            (await dispatch(
              authActions.verifyCodeFromSMS(code, dispatch, makeRedirect, receiveAmount)
            ))
        } else {
          await dispatch(authActions.verifyCodeFromSMS(code, dispatch, makeRedirect))
        }
      }
    },
    [code, dispatch, from, makeRedirect, receiveAmount]
  )
  return (
    <>
      {from === 'continue-transaction' && (
        <CalculationSection
          mode="calculation-input"
          receiveAmount={receiveAmount}
          setReceiveAmount={setReceiveAmount}
          setIsDisabledButton={setIsDisabledBtn}
          currency={receivePaymentData.currency}
          currencySign={receivePaymentData.currencySign}
        />
      )}
      <div className="verify-block from-input">
        <h4>Enter the text we just sent to your phone number at {phoneNumber}</h4>
        <form id="pin-code-form">
          <PinInput
            length={6}
            initialValue={code}
            onChange={onCodeChange}
            onComplete={onComplete}
            type="numeric"
            inputMode="numeric"
            focus
            inputStyle={errorField ? { background: '#FBDADA' } : { background: '#EFF6FF' }}
            ref={n => (codeInputs = n)}
          />
          <span className="form-input-error">{errorField}</span>
        </form>
      </div>
      <div className="show-mobile t-center">
        <button
          form="pin-code-form"
          className="btn btn-primary btn-xxl show btn-svg btnLogin-bottom"
          disabled={isDisabledButton}
          onClick={onVerify}
        >
          Verify
        </button>
      </div>
      <div className="btn-wrapper hide-mobile">
        <button
          form="pin-code-form"
          onClick={onVerify}
          className="btn btn-primary"
          disabled={isDisabledButton}
        >
          Verify
        </button>
      </div>
    </>
  )
}
export default CodeVerification
