import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import { PaymentSection } from '../../interfaces/cardPayment'
import { remittanceActions } from '../../redux/action-creators/remittanceActions'
import { Icon } from '../icons'
import Modal from '../Modal'
import { PuffLoader } from 'react-spinners'
import { WithdrawalItemForSummary } from '../../interfaces/calculation'
import { PaymentMethod, ShortWithdrawalPaymentProvider } from '../../interfaces'
import { BeneficiaryType } from '../../interfaces/beneficiary'

const getPaymentMethod = (paymentMethod: PaymentMethod | undefined) => {
  const methods = {
    [PaymentMethod.CreditCard]: 'Card Payment',
    [PaymentMethod.BankTransfer]: 'Bank Transfer',
    [PaymentMethod.OpenBanking]: 'Payment By Banking',
  }
  return paymentMethod ? methods[paymentMethod] : ''
}

type PaymentSummaryProps = {
  setPaymentSection: Dispatch<SetStateAction<PaymentSection>>
  txId: string
  withdrawalPaymentProviderOptions: Array<WithdrawalItemForSummary>
}

const PaymentSummary = ({
  setPaymentSection,
  txId,
  withdrawalPaymentProviderOptions,
}: PaymentSummaryProps) => {
  const dispatch = useAppDispatch()

  const tx = useAppSelector(state => state.remittance.transaction)
  const {
    getTransactionForPaymentLoadState,
    getPaymentProvidersLoadState,
    updateWithdrawalPaymentProviderLoadState,
  } = useAppSelector(state => state.remittance)

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenChangingReceiveMethodModal, setIsOpenChangingReceiveMethodModal] = useState(false)
  const [selectedReceiveMethod, setSelectedReceiveMethod] = useState<
    WithdrawalItemForSummary | undefined
  >()
  const [isOpenCompanyBeneficiaryWarning, setIsOpenCompanyBeneficiaryWarning] = useState(false)

  const isShowWithdrawalFee = useMemo(
    () => tx.withdrawalProviderId && tx.withdrawalMethod,
    [tx.withdrawalMethod, tx.withdrawalProviderId]
  )
  const isDisabledReceiveBtn = useMemo(
    () => withdrawalPaymentProviderOptions.length < 2,
    [withdrawalPaymentProviderOptions.length]
  )

  useEffect(() => {
    if (tx && tx.withdrawalProviderId && tx.withdrawalMethod) {
      // const currentWithdrawalPaymentProvider = {
      //   withdrawalProviderId: tx?.withdrawalProviderId,
      //   withdrawalMethod: tx?.withdrawalMethod,
      //   currency: tx.receiveCurrency,
      //   // title:
      // }
      const currentWithdrawalPaymentProvider = withdrawalPaymentProviderOptions.find(
        item =>
          item.currency === tx.receiveCurrency &&
          item.withdrawalProviderId === tx.withdrawalProviderId
      )

      setSelectedReceiveMethod(currentWithdrawalPaymentProvider)
    }

    // if (tx && withdrawalPaymentProviderOptions.length > 0 && !tx.withdrawalProviderId) {
    //   const bodyRequest = {
    //     withdrawalProviderId: withdrawalPaymentProviderOptions[0].withdrawalProviderId,
    //     withdrawalMethod: withdrawalPaymentProviderOptions[0].withdrawalMethod,
    //   }
    //   dispatch(remittanceActions.updateWithdrawalPaymentProvider(txId, bodyRequest))
    // }
  }, [tx, withdrawalPaymentProviderOptions])

  const isRateShown = useMemo(() => {
    return (
      window.location.hostname === 'hre.kuva.com' ||
      window.location.hostname === 'remittance.uat.kuvacash.net'
    )
  }, [])

  const onInfoClick = () => {
    setIsOpenModal(true)
  }

  const redirectToPaymentProviderScreen = useCallback(() => {
    setPaymentSection('payment-providers')
  }, [setPaymentSection])

  const onOpenChangingReceiveMethodModal = useCallback(() => {
    setIsOpenChangingReceiveMethodModal(true)
  }, [])

  const onChangeReceiveMethod = useCallback(
    (value: ShortWithdrawalPaymentProvider & { currency: string; title: string }) => {
      const bodyRequest = {
        withdrawalProviderId: value.withdrawalProviderId,
        withdrawalMethod: value.withdrawalMethod,
      }

      if (
        tx.recipient.type === BeneficiaryType.Company &&
        value.withdrawalMethod === PaymentMethod.Cash
      ) {
        setIsOpenCompanyBeneficiaryWarning(true)
        return
      }

      setSelectedReceiveMethod(value)
      dispatch(remittanceActions.updateWithdrawalPaymentProvider(txId, bodyRequest))
    },
    [dispatch, tx.recipient.type, txId]
  )

  const confirmCompanyBeneficiaryWarningModal = useCallback(() => {
    setIsOpenCompanyBeneficiaryWarning(false)
  }, [])

  return (
    <>
      <div className="payment-summary-section">
        <>
          {(getTransactionForPaymentLoadState.isLoading ||
            getPaymentProvidersLoadState.isLoading ||
            updateWithdrawalPaymentProviderLoadState.isLoading) && (
            <div className="spinner spinner-absolute">
              <PuffLoader size={70} color="#3171d8" />
            </div>
          )}
          <button className="payment-summary-back-btn" onClick={redirectToPaymentProviderScreen}>
            Back To Payment Method
          </button>
          <div className="payment-summary-info__row">
            <span>You send:</span>
            <span>
              {tx.srcCurrencySign}
              {(tx.amountToSend - tx.paymentProviderDiffFee).toFixed(2)}
            </span>
          </div>

          {!isRateShown && (
            <div className="payment-summary-info__row">
              <span className="tx-rate">
                Rate: 1.00 {tx.srcCurrency} = {tx.reverseRate?.toFixed(4)} {tx.receiveCurrency}
              </span>
            </div>
          )}
          <div className="payment-summary-info__row">
            <span>Transfer Time:</span>
            <span>
              <Icon.LightningChargeFill /> Within 10 minutes
            </span>
          </div>
          {isShowWithdrawalFee && (
            <>
              <div className="payment-summary__separate" />
              <div className="payment-summary-info__row">
                <span>Receive Method:</span>
                <div
                  className={`receive-method-btn-block${isDisabledReceiveBtn ? ' disabled' : ''}`}
                >
                  {selectedReceiveMethod?.withdrawalMethod && (
                    <span className="receive-method-label">
                      {/* {getWithdrawalBtnText(selectedReceiveMethod?.withdrawalMethod)} */}
                      {selectedReceiveMethod.title}
                    </span>
                  )}
                  {!isDisabledReceiveBtn && (
                    <button
                      className="receive-method-btn"
                      {...(!isDisabledReceiveBtn && { onClick: onOpenChangingReceiveMethodModal })}
                    >
                      <Icon.EditIcon />
                    </button>
                  )}
                </div>
              </div>
              <div className="payment-summary-info__row">
                <span className="tx-rate">
                  Fee:
                  {tx.srcCurrencySign}
                  {tx.withdrawalFee && tx.withdrawalFee.toFixed(2)}
                </span>
              </div>
            </>
          )}
          <div className="payment-summary__separate" />
          <div className="payment-summary-info__row">
            <span>Payment Method:</span>
            <span>{getPaymentMethod(tx.cashInPaymentMethod)}</span>
          </div>
          <div className="payment-summary-info__row">
            <span className="tx-rate">
              Fee:{' '}
              {tx.paymentProviderDiffFee > 0 ? (
                <>
                  {tx.srcCurrencySign}
                  {tx.paymentProviderDiffFee.toFixed(2)}
                </>
              ) : (
                'Free'
              )}
            </span>
          </div>
          <div className="payment-summary__separate" />
          <div className="payment-summary-info__total-row ">
            <span>Total to Pay:</span>
            <span>
              <span>
                {tx.srcCurrencySign}
                {tx.amountToSend.toFixed(2)}
              </span>
              <span className="info-icon" onClick={onInfoClick}>
                <Icon.Info />
              </span>
            </span>
          </div>
        </>
      </div>
      <Modal
        modalType="transactionDetails"
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        modalHeader="Transaction Details"
        modalBody={tx}
      />
      <Modal
        modalType="changingReceiveMethod"
        setIsOpenModal={setIsOpenChangingReceiveMethodModal}
        isOpenModal={isOpenChangingReceiveMethodModal}
        modalHeader="Receive Method"
        btnValuesForSelection={withdrawalPaymentProviderOptions}
        selectedBtnValue={selectedReceiveMethod}
        confirmFunction={onChangeReceiveMethod}
      />
      <Modal
        setIsOpenModal={setIsOpenCompanyBeneficiaryWarning}
        isOpenModal={isOpenCompanyBeneficiaryWarning}
        modalHeader="Not Available"
        modalBody="Company beneficiaries may only receive a payment to their bank account.
                  Please, change the receiving method and you will be able to send to this beneficiary"
        modalType="informationModal"
        confirmFunction={confirmCompanyBeneficiaryWarningModal}
      />
    </>
  )
}

export default PaymentSummary
