import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CompanyBeneficiaryFormType,
  CompanyRecipient,
  GetBeneficiaryFieldsConfigResponse,
  KWLRecipient,
} from '../../interfaces/beneficiary'
import { convertFormDataDependingOnBeneficiaryConfig, trimObjectFields } from '../../helpers/utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { beneficiaryYupSchemes } from '../../helpers/yupSchemes/beneficiaryYupSchemes'
import { Icon } from '../icons'
import FormInput from '../interfaceComponents/FormInput'
import { useAppDispatch, useAppSelector } from '../../hooks/dispatch'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import SelectForm from '../interfaceComponents/SelectForm'
import { Option } from '../../interfaces/commonTypes'
import { beneficiaryActions } from '../../redux/action-creators/beneficiaryActions'

type CompanyBeneficiaryFormProps = {
  recipient: CompanyRecipient
  canFormChange: boolean
  toggleEditingForm: () => void
  sendRequest: (recipient: KWLRecipient | Omit<CompanyBeneficiaryFormType, 'countryId'>) => void
  onCancel: () => void
  textButton: string
  isShownEditBtn: boolean
  beneficiaryFieldsConfig: GetBeneficiaryFieldsConfigResponse
  providerListForWithdrawalOptions?: Array<Option>
  countryOptionsForCompany: Array<Option>
  type: 'recipient' | 'beneficiary'
}

const CompanyBeneficiaryForm = ({
  sendRequest,
  canFormChange,
  toggleEditingForm,
  textButton,
  onCancel,
  isShownEditBtn,
  recipient,
  beneficiaryFieldsConfig,
  providerListForWithdrawalOptions,
  countryOptionsForCompany,
  type,
}: CompanyBeneficiaryFormProps) => {
  const dispatch = useAppDispatch()

  const {
    beneficiaryCreationLoadState,
    beneficiaryUpdateLoadState,
    validateWithdrawalAccountNumberLoadState,
    withdrawalAccountNumber,
    withdrawalBankId,
    withdrawalAccountNumberInfo,
    error: errorBeneficiary,
  } = useAppSelector(state => state.beneficiary)

  const errorTx = useAppSelector(state => state.remittance.error)
  const updateRecipientInTopUpLoadState = useAppSelector(
    state => state.remittance.updateRecipientInTopUpLoadState
  )

  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const isLoadingDisabledBtn = useMemo(() => {
    return (
      beneficiaryCreationLoadState.isLoading ||
      beneficiaryUpdateLoadState.isLoading ||
      updateRecipientInTopUpLoadState.isLoading ||
      validateWithdrawalAccountNumberLoadState.isLoading
    )
  }, [
    beneficiaryCreationLoadState.isLoading,
    beneficiaryUpdateLoadState.isLoading,
    updateRecipientInTopUpLoadState.isLoading,
    validateWithdrawalAccountNumberLoadState.isLoading,
  ])

  const isVisibleWithdrawalFields = useMemo(
    () =>
      beneficiaryFieldsConfig?.Company.AccountNumber.visible &&
      beneficiaryFieldsConfig?.Company.BankName.visible &&
      beneficiaryFieldsConfig?.Company.NotificationEmail.visible &&
      beneficiaryFieldsConfig?.Company.Reference.visible,
    [
      beneficiaryFieldsConfig?.Company.AccountNumber.visible,
      beneficiaryFieldsConfig?.Company.BankName.visible,
      beneficiaryFieldsConfig?.Company.NotificationEmail.visible,
      beneficiaryFieldsConfig?.Company.Reference.visible,
    ]
  )

  useEffect(() => {
    return () => {
      dispatch(beneficiaryActions.clearWithdrawalAccountInfo())
    }
  }, [dispatch])

  const { register, handleSubmit, formState, setValue, setError, clearErrors, watch } =
    useForm<CompanyBeneficiaryFormType>({
      resolver: yupResolver(
        beneficiaryYupSchemes.companyBeneficiaryScheme(beneficiaryFieldsConfig)
      ),
    })

  const watchedBankId = watch('bankId')
  const watchedAccountNumber = watch('accountNumber')
  const watchedReference = watch('reference')
  const watchedNotificationEmail = watch('notificationEmail')

  const isCertainProviderName = useMemo(() => {
    if (!providerListForWithdrawalOptions) {
      return false
    }

    const currentWithdrawalProviderName = providerListForWithdrawalOptions.filter(
      providerItem => String(providerItem.value) === String(watchedBankId)
    )

    return currentWithdrawalProviderName[0]?.label === 'CBZ'
  }, [providerListForWithdrawalOptions, watchedBankId])

  const isValidAccountNumber = useMemo(() => {
    if (!isCertainProviderName || !isVisibleWithdrawalFields) {
      return true
    }

    const currentAccountNumber = watchedAccountNumber ? String(watchedAccountNumber).trim() : ''

    return (
      currentAccountNumber === withdrawalAccountNumber &&
      String(watchedBankId) === String(withdrawalBankId) &&
      !errorBeneficiary &&
      withdrawalAccountNumberInfo
    )
  }, [
    errorBeneficiary,
    isCertainProviderName,
    isVisibleWithdrawalFields,
    watchedAccountNumber,
    watchedBankId,
    withdrawalAccountNumber,
    withdrawalAccountNumberInfo,
    withdrawalBankId,
  ])

  const isShownWithdrawalInfo = useMemo(() => {
    if (!isCertainProviderName || !isVisibleWithdrawalFields) {
      return false
    }

    const currentAccountNumber = watchedAccountNumber ? String(watchedAccountNumber).trim() : ''

    return (
      currentAccountNumber === withdrawalAccountNumber &&
      String(watchedBankId) === String(withdrawalBankId) &&
      !errorBeneficiary &&
      withdrawalAccountNumberInfo
    )
  }, [
    errorBeneficiary,
    isCertainProviderName,
    isVisibleWithdrawalFields,
    watchedAccountNumber,
    watchedBankId,
    withdrawalAccountNumber,
    withdrawalAccountNumberInfo,
    withdrawalBankId,
  ])

  const updateCashOutAccountSectionErrors = useCallback(() => {
    setValue('accountNumber', watchedAccountNumber, { shouldValidate: true })
    setValue('reference', watchedReference, { shouldValidate: true })
    setValue('notificationEmail', watchedNotificationEmail, { shouldValidate: true })
  }, [setValue, watchedNotificationEmail, watchedReference, watchedAccountNumber])

  useEffect(() => {
    if (watchedBankId && watchedBankId !== '-' && formState.isSubmitted) {
      updateCashOutAccountSectionErrors()
    }

    if (watchedBankId === '-' && formState.isSubmitted) {
      updateCashOutAccountSectionErrors()
    }
  }, [formState.isSubmitted, updateCashOutAccountSectionErrors, watchedBankId])

  useEffect(() => {
    if (errorBeneficiary) {
      return setError('accountNumber', { message: errorBeneficiary })
    } else {
      return clearErrors('accountNumber')
    }
  }, [clearErrors, errorBeneficiary, setError])

  useEffect(() => {
    if (errorTx && typeof errorTx === 'string') {
      return setError('accountNumber', { message: errorTx })
    } else {
      return clearErrors('accountNumber')
    }
  }, [clearErrors, errorTx, setError])

  const setFormValue = useCallback(
    (recipient: CompanyRecipient) => {
      //setValue('companyName', recipient.companyName || '')
      setValue('companyName', recipient.companyName || 'DATVEST NORTHGATE ESTATES')
      setValue('countryId', 240)

      //setValue('bankId', recipient.bankId || undefined)
      setValue('bankId', recipient.bankId || 1)
      //setValue('accountNumber', recipient.accountNumber || '')
      setValue('accountNumber', recipient.accountNumber || '11323950550402')
      setValue('reference', recipient.reference || '')
      // setValue('notificationEmail', recipient.notificationEmail || '')
      setValue('notificationEmail', recipient.notificationEmail || 'datvestops@cbz.co.zw')
    },
    [setValue]
  )

  useEffect(() => {
    if (isVisibleWithdrawalFields && providerListForWithdrawalOptions && recipient) {
      setFormValue(recipient)
      return
    }
    if (recipient) {
      setFormValue(recipient)
      return
    }
  }, [isVisibleWithdrawalFields, providerListForWithdrawalOptions, recipient, setFormValue])

  useEffect(() => {
    if (isEmpty(formState.errors)) {
      setIsDisabledButton(false)
    } else {
      setIsDisabledButton(true)
    }
  }, [formState])

  const onVerify = (data: CompanyBeneficiaryFormType) => {
    const omittedData = omit(data, ['countryId'])
    const modifiedRecipientDetails = convertFormDataDependingOnBeneficiaryConfig(
      omittedData,
      beneficiaryFieldsConfig
    ) as CompanyBeneficiaryFormType
    const recipient = trimObjectFields<CompanyBeneficiaryFormType>(modifiedRecipientDetails)

    if (!isValidAccountNumber && canFormChange) {
      if (data.accountNumber && data.bankId) {
        const requestData = {
          accountNumber: data.accountNumber,
          bankId: data.bankId,
        }

        dispatch(beneficiaryActions.validateWithdrawalAccountNumber(requestData))
        return
      }
    }

    sendRequest(recipient)
  }

  const textConfirmButton = useMemo(() => {
    if (isValidAccountNumber || !canFormChange) {
      return textButton
    }

    return 'Validate'
  }, [canFormChange, isValidAccountNumber, textButton])

  return (
    <>
      <form id="beneficiary-details-form" onSubmit={handleSubmit(onVerify)}>
        <div className="details-section">
          <div className="details-section__title">
            <h2>Company Details</h2>
            {!canFormChange && isShownEditBtn && (
              <button className="editButtons-text" onClick={toggleEditingForm} type="button">
                <Icon.EditIcon />
                <span className="fz-small">Edit...</span>
              </button>
            )}
          </div>
          {beneficiaryFieldsConfig?.Company.CompanyName.visible && (
            <FormInput<CompanyBeneficiaryFormType>
              id="companyName"
              register={register}
              error={formState.errors.companyName?.message}
              className="formInput"
              type="string"
              maxLength={32}
              label="Company Name"
              // disabled={!canFormChange}
              disabled
            />
          )}
          <SelectForm<CompanyBeneficiaryFormType>
            id="countryId"
            register={register}
            // error={formState.errors.countryId?.message}
            options={countryOptionsForCompany}
            className="select-form"
            defaultOption="Select Country"
            disabled={!canFormChange}
            watch={watch}
          />
          {isVisibleWithdrawalFields && (
            <>
              <h2>Foreign Currency Account/ Mobile Wallet Details</h2>

              <SelectForm<CompanyBeneficiaryFormType>
                id="bankId"
                register={register}
                options={providerListForWithdrawalOptions || []}
                className="select-form"
                defaultOption="Select a Bank"
                // disabled={!canFormChange}
                disabled
                watch={watch}
                error={formState.errors.bankId?.message}
              />
              <FormInput<CompanyBeneficiaryFormType>
                id="accountNumber"
                register={register}
                error={formState.errors.accountNumber?.message}
                className="formInput"
                type="string"
                maxLength={32}
                label="USD Bank or Wallet account No or Merchant Code"
                // disabled={!canFormChange}
                disabled
              />
              {isShownWithdrawalInfo && (
                <div className="withdrawal-account-number-info">
                  <div className="withdrawal-account-number-info-raw">
                    <div>Account Name:</div>
                    <div>{withdrawalAccountNumberInfo?.holderName}</div>
                  </div>
                  <div className="withdrawal-account-number-info-raw">
                    <div>Currency:</div>
                    <div>{withdrawalAccountNumberInfo?.currency}</div>
                  </div>
                </div>
              )}
              <FormInput<CompanyBeneficiaryFormType>
                id="reference"
                register={register}
                error={formState.errors.reference?.message}
                className="formInput"
                type="string"
                maxLength={64}
                label="Beneficiary Reference"
                disabled={!canFormChange}
                additionalLabel='*Fill the field like: Stand no & Buyer Name & Surname'
              />
              <FormInput<CompanyBeneficiaryFormType>
                id="notificationEmail"
                register={register}
                error={formState.errors.notificationEmail?.message}
                className="formInput"
                type="string"
                maxLength={32}
                label="Beneficiary email (CBZ will send proof of payment)"
                // disabled={!canFormChange}
                disabled
              />
            </>
          )}
        </div>
        <div className="error-message">
          <Icon.WarningExclamationMark />
          <p>
            Please ensure that account details entered are correct as error will lead to delayed /
            failed transactions
          </p>
        </div>
      </form>
      <div className="btn-wrapper mb-10">
        <button type="button" className="btn btn-cancel" onClick={onCancel}>
          Cancel
        </button>

        {(type === 'beneficiary' || (canFormChange && type === 'recipient')) && (
          <button
            form="beneficiary-details-form"
            type="submit"
            className="btn btn-next"
            disabled={isDisabledButton || isLoadingDisabledBtn}
          >
            {textConfirmButton}
          </button>
        )}
      </div>
    </>
  )
}

export default CompanyBeneficiaryForm
