import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch'
import { Icon } from './icons'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { authActions } from '../redux/action-creators/authActions'
import { isUserDataExist } from '../helpers/userHelper'
import BuildVersion from './BuildVersion'
import { AmlStatus } from '../interfaces/user'

const SendMoneyPathes = ['/', '/beneficiaries/select-beneficiary', '/transaction/new']

const MainHeader: React.FC = props => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const userDetails = useAppSelector(state => state.user.userDetails)
  const userAmlStatus = useAppSelector(state => state.user.userAml?.status)
  const preset = useAppSelector(state => state.preset.preset)
  const mobileMenuRef = useRef<HTMLDivElement>(null)
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false)

  const isVerified = userDetails?.status === 20
  const hasFullUserData = userDetails && isUserDataExist(userDetails)
  const isEmailConfirmed = userDetails && userDetails?.isEmailConfirmed
  const isRedirectChange = useMemo(
    () =>
      window.location.hostname === 'localhost' ||
      window.location.hostname === 'remittance.dev.kuvacash.net' ||
      window.location.hostname === 'send.zikicash.com',
    []
  )

  const closeMobileMenu = useCallback(() => {
    setIsOpenMobileMenu(false)
  }, [])

  const onClickAway = useCallback(
    (event: any) => {
      if (!mobileMenuRef.current?.contains(event.target)) {
        event.stopPropagation()
        closeMobileMenu()
      }
    },
    [closeMobileMenu]
  )
  useEffect(() => {
    if (isOpenMobileMenu) {
      document.addEventListener('click', onClickAway, { capture: true })
      document.body.classList.add('overflow-hidden')
      return () => {
        document.body.classList.remove('overflow-hidden')
        document.removeEventListener('click', onClickAway, { capture: true })
      }
    }
  }, [isOpenMobileMenu, onClickAway])

  const appHeight = () => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight - 80}px`)
  }
  useEffect(() => {
    window.addEventListener('load', appHeight)
  }, [])

  const onToggleMenu = () => {
    setIsOpenMobileMenu(isOpenMobileMenu => !isOpenMobileMenu)
  }

  const onSingUp = () => {
    navigate('/login', { state: { from: 'sing-up' } })
  }

  const onLogOut = async () => {
    await dispatch(authActions.logOutAction())

    navigate('/')
  }

  const isSendMoneyFlow = useMemo(
    () => SendMoneyPathes.find(path => path === pathname),

    [pathname]
  )

  const isMobileHeader = pathname === '/' ? '' : ' header-mobile'

  const helpUrl = useMemo(() => {
    // if (window.location.hostname === 'localhost') {
    //   return 'https://wpdev04.newlinetechno.net/contact/'
    // }
    if (window.location.hostname === 'uat.zikicash.com') {
      return 'https://wpdev04.newlinetechno.net/contact/'
    }
    if (window.location.hostname === 'send.zikicash.com') {
      return 'https://zikicash.com/contact'
    }

    return '/help'
  }, [])

  return (
    <header className={`header${isMobileHeader}`}>
      <div className="container container-xxl">
        <div className="logo-wrapper">
          {isRedirectChange ? (
            <a href="https://zikicash.com/" className="logo" rel="noreferrer">
              <img alt="Logo" src={preset?.mainLogoSvg} />
            </a>
          ) : (
            <Link to="/" className="logo">
              <img alt="Logo" src={preset?.mainLogoSvg} />
            </Link>
          )}
          {preset?.additionalLogo && (
            <div className="poweredBy">
              <img alt="Additional Logo" src={preset?.additionalLogo} />
            </div>
          )}
        </div>
        <button
          onClick={onToggleMenu}
          className={`profile-btn${isOpenMobileMenu ? ' active' : ''}`}
          aria-controls="primary-menu"
          aria-expanded="false"
        >
          <Icon.User />
          <span></span>
        </button>
        {userDetails === null ? (
          <>
            <div className="profile">
              <nav className="profile-general-menu hide-mobile">
                {isRedirectChange ? (
                  <a href="https://zikicash.com/" rel="noreferrer">
                    Home
                  </a>
                ) : (
                  <NavLink to="/">Home</NavLink>
                )}
                {helpUrl === '/help' ? (
                  <NavLink to={'/help'}>Contact Us</NavLink>
                ) : (
                  <a href={helpUrl} rel="noreferrer">
                    Contact Us
                  </a>
                )}
                {/* <NavLink to="/help">Contact Us</NavLink> */}
                {/* <NavLink to='/faq'>FAQ</NavLink> */}
              </nav>
              <button className="btn btn-logIn btn-additional hide-mobile" onClick={onSingUp}>
                Sign Up | Log In
              </button>
            </div>
            {(isVerified || isOpenMobileMenu) && (
              <div className={`profile-block-wrapper${isOpenMobileMenu ? ' active' : ''}`}>
                <div
                  ref={mobileMenuRef}
                  onClick={closeMobileMenu}
                  className={`profile-block${isOpenMobileMenu ? ' active' : ''}`}
                >
                  <div className="profile">
                    <div className="profile-home">
                      {isRedirectChange ? (
                        <a href="https://zikicash.com/" rel="noreferrer">
                          <Icon.ArrowLeftBack />
                          Home
                        </a>
                      ) : (
                        <Link to="/">
                          <Icon.ArrowLeftBack />
                          Home
                        </Link>
                      )}
                    </div>
                    <nav className="profile-general-menu">
                      {helpUrl === '/help' ? (
                        <NavLink to={'/help'}>Contact Us</NavLink>
                      ) : (
                        <a href={helpUrl} rel="noreferrer">
                          Contact Us
                        </a>
                      )}
                      {/* <NavLink to="/help">Contact Us</NavLink> */}
                      {/* <NavLink to='/faq'>FAQ</NavLink> */}
                    </nav>
                    <button onClick={onSingUp} className="btn btn-logOut btn-additional">
                      Sign Up | Log In
                    </button>
                  </div>
                  <BuildVersion className="build-version-mobile" />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={`profile-block-wrapper${isOpenMobileMenu ? ' active' : ''}`}>
              <div
                ref={mobileMenuRef}
                onClick={closeMobileMenu}
                className={`profile-block${isOpenMobileMenu ? ' active' : ''}`}
              >
                <div className="profile">
                  <div className="profile-home">
                    {isRedirectChange ? (
                      <a href="https://zikicash.com/" rel="noreferrer">
                        <Icon.ArrowLeftBack />
                        Home
                      </a>
                    ) : (
                      <Link to="/">
                        <Icon.ArrowLeftBack />
                        Home
                      </Link>
                    )}
                  </div>
                  <nav className="profile-general-menu">
                    {isRedirectChange ? (
                      <a href="https://zikicash.com/" rel="noreferrer" className="hide-mobile">
                        Home
                      </a>
                    ) : (
                      <NavLink to="/" className="hide-mobile">
                        Home
                      </NavLink>
                    )}
                    <NavLink to={'/profile'} state={{ pathname }} className="hide-mobile">
                      My Account
                    </NavLink>
                    {helpUrl === '/help' ? (
                      <NavLink to={'/help'}>Contact Us</NavLink>
                    ) : (
                      <a href={helpUrl} rel="noreferrer">
                        Contact Us
                      </a>
                    )}
                    {/* <NavLink to="/help">Contact Us</NavLink> */}
                    {/* <NavLink to='/faq'>FAQ</NavLink> */}
                  </nav>
                  <div className="profile-details">
                    <Link className="profile-name" to="/profile" state={{ pathname }}>
                      Welcome
                      {userDetails?.firstName && `, ${userDetails?.firstName}`}
                    </Link>
                    {userDetails && <span className="profile-tel">{userDetails?.phoneNumber}</span>}
                    {userAmlStatus === AmlStatus.Verified && (
                      <div className="fz-xSmall verified-success">
                        <Icon.SuccessTick /> Verified
                      </div>
                    )}
                    <div className="profile-arrow show-mobile">
                      <Link className="profile-name t-center" to="/profile" state={{ pathname }}>
                        <Icon.ArrowRightBack />
                      </Link>
                    </div>
                  </div>

                  <button onClick={onLogOut} className="btn btn-logOut btn-additional">
                    <Icon.Door /> Log Out
                  </button>
                  <div className="profile-links">
                    <a href={preset?.termAndConditionsPdf} target="_blank" rel="noreferrer">
                      Terms and Conditions
                    </a>
                    <strong> - </strong>
                    <a href={preset?.privacyPolicyPdf} target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>
                    <strong> - </strong>
                    <a href={preset?.websiteTermsOfUsePdf} target="_blank" rel="noreferrer">
                      Website Terms of Use
                    </a>
                  </div>
                </div>
                <BuildVersion className="build-version-mobile show-mobile" />

                {isVerified && hasFullUserData && isEmailConfirmed && (
                  <nav className="profile-personal-menu">
                    <NavLink to={'/'} className={Boolean(isSendMoneyFlow) ? 'active' : ''}>
                      Send Money
                    </NavLink>
                    <Link
                      to={'/beneficiaries'}
                      className={
                        pathname !== '/beneficiaries/select-beneficiary' &&
                        pathname === '/beneficiaries'
                          ? 'active'
                          : ''
                      }
                    >
                      My Beneficiaries
                    </Link>
                    <NavLink to={'/past-transactions'}>My Transactions</NavLink>
                    {helpUrl === '/help' ? (
                      <NavLink to={'/help'}>Help and Support</NavLink>
                    ) : (
                      <a href={helpUrl} rel="noreferrer">
                        Help and Support
                      </a>
                    )}
                    {/* <NavLink to={'/help'}>Help and Support</NavLink> */}
                  </nav>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  )
}

export default MainHeader
